import CTAButton from "components/CTAButton";

const InfoCardVertical = ({
  img,
  title,
  body,
  btnText,
  btnLink,
  minutesToRead,
  publishedDate,
}) => {
  return (
    <div className="card mx-auto mb-4 h-full w-80 bg-base-100 shadow-lg lg:w-96">
      <figure className="h-60">
        <img
          className="h-full w-full object-cover object-center"
          src={img}
          alt="Shoes"
        />
      </figure>
      <div className="card-body gap-8 px-5 lg:px-5">
        <div className="ml-3 flex flex-col gap-4">
          {publishedDate && (
            <div className="text-brand-gray my-0 mb-3 flex w-fit flex-row gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              >
                <path
                  d="M19.642 3.1972H17.7329V2.5072C17.7329 1.9322 17.2499 1.44922 16.6749 1.44922H15.9159C15.3409 1.44922 14.8579 1.9322 14.8579 2.5072V3.1972H8.11891V2.5072C8.11891 1.9322 7.63593 1.44922 7.06093 1.44922H6.30189C5.72689 1.44922 5.24391 1.9322 5.24391 2.5072V3.1972H3.35795C1.81699 3.1972 0.574951 4.43922 0.574951 5.9802V18.7912C0.574951 20.3322 1.81697 21.5742 3.35795 21.5742H17.342C17.8249 21.5742 18.2849 21.3902 18.6299 21.0452L21.8729 17.8022C22.2179 17.4572 22.402 16.9972 22.402 16.5142V5.98018C22.4249 4.43928 21.1829 3.19718 19.642 3.19718L19.642 3.1972ZM3.35795 4.9222H19.642C20.217 4.9222 20.6999 5.40518 20.6999 5.98018V7.42922H2.29993V5.98018C2.29993 5.3822 2.78291 4.9222 3.35791 4.9222H3.35795ZM2.29997 18.7912V9.1312H20.7V15.1342L17.825 15.1343C16.813 15.1343 15.985 15.9623 15.985 16.9743V19.8493H3.33497C2.78295 19.8262 2.29997 19.3662 2.29997 18.7912ZM17.71 19.4812V16.9742C17.71 16.9052 17.7559 16.8592 17.825 16.8592H20.355L17.71 19.4812Z"
                  fill="#6163F6"
                />
                <path
                  d="M6.62411 12.2361C6.62411 12.9093 6.0783 13.4551 5.40507 13.4551C4.73184 13.4551 4.18604 12.9093 4.18604 12.2361C4.18604 11.5628 4.73184 11.0171 5.40507 11.0171C6.0783 11.0171 6.62411 11.5628 6.62411 12.2361Z"
                  fill="#6163F6"
                />
                <path
                  d="M10.6719 12.2361C10.6719 12.9093 10.1261 13.4551 9.45285 13.4551C8.77962 13.4551 8.23389 12.9093 8.23389 12.2361C8.23389 11.5628 8.77962 11.0171 9.45285 11.0171C10.1261 11.0171 10.6719 11.5628 10.6719 12.2361Z"
                  fill="#6163F6"
                />
                <path
                  d="M14.72 12.2361C14.72 12.9093 14.1742 13.4551 13.5009 13.4551C12.8277 13.4551 12.282 12.9093 12.282 12.2361C12.282 11.5628 12.8277 11.0171 13.5009 11.0171C14.1742 11.0171 14.72 11.5628 14.72 12.2361Z"
                  fill="#6163F6"
                />
                <path
                  d="M18.7681 12.2361C18.7681 12.9093 18.2223 13.4551 17.5491 13.4551C16.8759 13.4551 16.3301 12.9093 16.3301 12.2361C16.3301 11.5628 16.8759 11.0171 17.5491 11.0171C18.2223 11.0171 18.7681 11.5628 18.7681 12.2361Z"
                  fill="#6163F6"
                />
                <path
                  d="M6.62411 16.5832C6.62411 17.2564 6.0783 17.8023 5.40507 17.8023C4.73184 17.8023 4.18604 17.2564 4.18604 16.5832C4.18604 15.91 4.73184 15.3643 5.40507 15.3643C6.0783 15.3643 6.62411 15.91 6.62411 16.5832Z"
                  fill="#6163F6"
                />
                <path
                  d="M10.6719 16.5832C10.6719 17.2564 10.1261 17.8023 9.45285 17.8023C8.77962 17.8023 8.23389 17.2564 8.23389 16.5832C8.23389 15.91 8.77962 15.3643 9.45285 15.3643C10.1261 15.3643 10.6719 15.91 10.6719 16.5832Z"
                  fill="#6163F6"
                />
                <path
                  d="M14.72 16.5832C14.72 17.2564 14.1742 17.8023 13.5009 17.8023C12.8277 17.8023 12.282 17.2564 12.282 16.5832C12.282 15.91 12.8277 15.3643 13.5009 15.3643C14.1742 15.3643 14.72 15.91 14.72 16.5832Z"
                  fill="#6163F6"
                />
              </svg>
              {publishedDate}
            </div>
          )}

          <div
            className="card-title flex min-h-14 w-full items-start justify-start text-left text-2xl font-bold md:text-s3"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
        </div>
        <p
          className="-mb-6 ml-3"
          dangerouslySetInnerHTML={{ __html: body }}
        ></p>
        <div className="card-actions mt-3 flex flex-row items-center justify-center gap-5">
          <CTAButton
            hasArrow
            btnText={btnText}
            href={btnLink}
            opensNewTab
            size={1}
          />
          {minutesToRead && (
            <div className="flex flex-row gap-2 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
              >
                <path
                  d="M11.2501 10.5588V4.8726C11.2501 4.45879 10.9142 4.12305 10.5001 4.12305C10.0861 4.12305 9.75012 4.45879 9.75012 4.8726V10.8691C9.75012 11.0682 9.82922 11.2585 9.96985 11.399L12.9699 14.3972C13.1163 14.5436 13.3077 14.6168 13.5001 14.6168C13.6925 14.6168 13.8839 14.5436 14.0304 14.3972C14.3234 14.1045 14.3234 13.6301 14.0304 13.3373L11.2501 10.5588Z"
                  fill="#6163F6"
                />
                <path
                  d="M10.5 0.375C4.71 0.375 0 5.08222 0 10.8688C0 16.6554 4.71 21.3626 10.5 21.3626C16.29 21.3626 21 16.6554 21 10.8688C21 5.08222 16.29 0.375 10.5 0.375ZM10.5 19.8635C5.53708 19.8635 1.5 15.8288 1.5 10.8688C1.5 5.90881 5.53708 1.87411 10.5 1.87411C15.4629 1.87411 19.5 5.90881 19.5 10.8688C19.5 15.8288 15.4629 19.8635 10.5 19.8635Z"
                  fill="#6163F6"
                />
              </svg>
              {minutesToRead} MIN READ
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoCardVertical;
