import CTAButton from 'components/CTAButton';

const Hero = () => {
    return (
        <section className="grid grid-cols-7 gap-5 h-fit">
            <div className='flex flex-col-reverse lg:grid grid-cols-2 bg-[url("assets/images/home/hero-1-bg.png")] w-fit h-full bg-cover pt-10 p overflow-hidden rounded-2xl lg:col-span-4 col-span-full'>
                <div className='mt-auto w-full flex items-center justify-center'>
                    <img src={require('assets/images/home/hero-1-man.png')} alt="" className='hidden lg:block' />
                    <img src={require('assets/images/home/hero-1-man-mobile.png')} alt="" className='block lg:hidden' />
                </div>
                <div className='flex flex-col items-center justify-center text-center lg:items-start lg:justify-normal lg:text-left px-5 bg-opacity-50 relative gap-5 pr-5 xl:-ml-[25%] lg:-ml-0'>
                    <div className='ml-auto mb-7 max-w-24 hidden lg:block'>
                        <img src={require('assets/images/home/hero-1-trust-badge.png')} alt="" />
                    </div>
                    <div className='flex flex-col gap-10 '>
                        <div className='text-4xl md:text-5xl font-bold leading-tight'>
                            Strategic Consulting for HR, Finance, and Security
                        </div>
                        <div className='flex flex-row gap-5 items-center justify-center lg:justify-start lg:items-start mb-10 lg:mx-auto w-full'>
                            <CTAButton btnText='Book a Call' href='/about-us#connect-with-us' hasArrow opensNewTab={false} />
                            {/* <a href="">Our <u>Services</u></a> */}
                        </div>
                    </div>
                    {/* <div className='flex flex-row gap-3 mt-auto ml-auto mb-5 mr-5 items-center'>
                        <div>
                            Trusted by <strong>89 Saas</strong>
                        </div>
                        <img src={require('assets/images/home/hero-1-headshots.png')} alt="" />
                    </div> */}
                </div>
            </div>
            <div className='hidden lg:block col-span-3 h-full rounded-2xl overflow-hidden'>
                <img src={require('assets/images/home/hero-2.png')} alt="" className='h-full w-full object-cover' />
            </div>
        </section>
    );
}

export default Hero;