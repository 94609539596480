import { useEffect, useRef } from "react";

const HowWeDoit = () => {
    const videoRef = useRef(null);

    const scrollHandler = () => {
        if (videoRef.current) {
            const videoRect = videoRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Calculate the visible percentage of the video
            const visibleHeight = Math.min(videoRect.bottom, windowHeight) - Math.max(videoRect.top, 0);
            const visiblePercentage = (visibleHeight / videoRect.height) * 100;

            if (visiblePercentage >= 20) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [])


    return (
        <section className="flex flex-col gap-0 justify-between items-center mx-auto max-w-[1400px] my-10 lg:my-20 px-10">
            <div className="gap-8 md:gap-12 flex flex-col mb-5 md:mb-10 text-center w-full">
                <h2 className="text-4xl md:text-5xl font-semibold">Streamlining Employee Lifecycle Transactions:<br /> HR, Finance, and IT Integration</h2>
                <p className="text-xl  text-justify">
                    Cascade Clarity's comprehensive understanding of both business operations and technical solutions, particularly in 
                    HR, Finance, and IT intersections sets us apart. We excel at both business strategy and technical execution. 
                    We specialize in streamlining employee lifecycle transactions, eliminating process waste, and transforming complex organizational 
                    data into strategic assets.</p>
            </div>
            <div className="w-full max-w-[700px]">
                <video ref={videoRef} src={require('assets/videos/home/employee-lifecycle.mp4')} alt="Employee Life Cycle" width="100%" height="100%" loading="lazy" muted loop playsInline></video>
            </div>
            <div className="gap-10 flex flex-col text-justify mx-auto">
                {/* <h2 className="text-5xl font-semibold text-brand-darkGrey">How We Do It</h2> */}
                {/* <CTAButton btnText='Book a Call' href='#' hasArrow opensNewTab={false} />
                <a href="/" className="hover:text-brand-purple text-black">Learn more</a> */}
            </div>
        </section>
    );
}

export default HowWeDoit;