import CTAButton from "components/CTAButton";

const CTABanner = () => {
    return (
        <section id="connect" className="bg-[url('assets/images/home/cta-banner-bg.png')] bg-cover w-full max-w-[1500px] mx-auto rounded-b-[20px]">
            <div className="w-1/2 h-full pl-24 pr-2 justify-center flex flex-col gap-10 pt-44 pb-20">
                <h2 className="text-white text-6xl font-bold leading-[1.05]">
                    Start Your <br />
                    <span className="text-brand-purple">Transformation</span><br />
                    Journey Now
                </h2>
                <p className="text-white text-lg">
                    Are you ready to redefine how your business operates? Start <br /> now and align your operations with your future vision.
                </p>
                <CTAButton btnText='See more' href='#' hasArrow opensNewTab={false} />

            </div>
        </section>
    );
}

export default CTABanner;