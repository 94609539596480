export const naviagtionData = [
  {
    id: 1,
    name: "Services",
    link: "/services",
    submenu: [
      {
        id: 1,
        name: "AI Innovation and Adoption",
        link: "/services/#ai-solutions",
      },
      {
        id: 2,
        name: "Digital Strategy & Alignment",
        link: "/services/#digital-transformation",
      },
      {
        id: 3,
        name: "Strategic Vendor Management",
        link: "/services/#vendor-selection",
      },
      {
        id: 4,
        name: "Enterprise Implementation",
        link: "/services/#integration-services",
      },
    ],
  },
  // {
  //     id: 2,
  //     name: "How We Do It",
  //     link: "/how-we-do-it",
  //     submenu: [
  //         { id: 1, name: "Visual Layer", link: "/how-we-do-it/#visual-layer" },
  //         { id: 2, name: "Integration Strategies", link: "/how-we-do-it/#integration-strategies" },
  //         { id: 3, name: "Process Mining", link: "/how-we-do-it/#process-mining" }
  //     ]
  // },
  {
    id: 3,
    name: "Technology",
    link: "/technology",
    submenu: [
      { id: 1, name: "Generative AI/ML", link: "/technology/#generative-ai" },
      { id: 2, name: "Dashboards", link: "/technology/#dashboards" },
      {
        id: 3,
        name: "Human Capital Management",
        link: "/technology/#human-capital-management",
      },
      {
        id: 4,
        name: "Finance and FP&A",
        link: "/technology/#financial-planning",
      },
      { id: 5, name: "Security", link: "/technology/#security" },
      { id: 6, name: "Integration", link: "/technology/#integration" },
    ],
  },
  {
    id: 4,
    name: "Success Stories",
    link: "/success-stories",
    submenu: [
      {
        id: 1,
        name: "AI: HR Automation",
        link: "/success-stories#AI-HR-Automation",
      },
      {
        id: 2,
        name: "Global HRIS vs Payroll",
        link: "/success-stories#Global-HRIS-vs-Payroll",
      },
      {
        id: 3,
        name: "HR: IT Integration",
        link: "/success-stories#HR-IT-Integration",
      },
      {
        id: 4,
        name: "Strategic Dashboard Analytics",
        link: "/success-stories#Strategic-Dashboard-Analytics",
      },
    ],
  },
  {
    id: 5,
    name: "Blog Posts",
    link: "/blog/linkedin",
    submenu: [
      { id: 2, name: "LinkedIn", link: "/blog/linkedin" },
      { id: 1, name: "Articles", link: "/blog/articles" },
      // { id: 3, name: "News", link: "/blog/articles" },
    ],
  },
  {
    id: 6,
    name: "About Us",
    link: "/about-us",
    submenu: [
      { id: 1, name: "Leadership", link: "/leadership" },
      { id: 3, name: "Why Cascade Clarity", link: "/about-us#why-us" },
      { id: 4, name: "Connect with us", link: "/about-us#connect-with-us" },
    ],
  },
];
