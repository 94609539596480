import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import { Helmet } from "react-helmet";
import SuccessStoriesList from "./StoriesList";
import CTAButton from "components/CTAButton";
import Connect from "pages/Home/Connect";

const SuccessStories = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <Helmet>
        <title>Success Stories | Cascade Clarity</title>
      </Helmet>
      <NavBar />
      <SubpageHero
        pageTitle="Success Stories"
        testimonial={{
          quote:
            "\"Cascade Clarity's C-level vision and deep tech acumen revolutionized our digital roadmap for Global HRIS and Payroll. Their strategic insights aligned perfectly with our business goals.\"",
          author: "John B. Chief People Officer - Afiniti",
        }}
      />

      <section
        id="success-stories" className="my-0 flex w-full flex-col items-center justify-center gap-6 rounded-lg bg-brand-lightBlue bg-opacity-10 px-5 py-10 text-center"
      >
         <h2 className="text-4xl font-bold text-brand-purple">
          Driving Operational Success Together
        </h2>
        <div className="max-w-4xl text-xl leading-relaxed">
        Explore our case studies to see how we have driven real change in HR, Finance, and IT.  We bring hands-on operational experience, rolling up our sleeves to make strategic plans reality.
        </div>
      </section>
      <SuccessStoriesList />
      <Connect />
      <Footer />
     
    </main>
  );
};

export default SuccessStories;
