import { useCallback } from "react";

const useScrollWithOffset = () => {
  const scrollWithOffset = useCallback((el) => {
    if (!el) return;

    const navBarHeight = document.getElementById("nav-bar")?.offsetHeight || 0;
    const yCoordinate =
      el.getBoundingClientRect().top + window.pageYOffset - navBarHeight;
    const yOffset = -40;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  }, []);

  return scrollWithOffset;
};

export default useScrollWithOffset;
