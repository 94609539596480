function isValidDate(dateString) {
  if (typeof dateString !== "string") return false;
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (!regex.test(dateString)) return false;
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const parsePostDate = (date) => {
  if (isValidDate(date)) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  } else {
    console.error("Invalid date");
    return "";
  }
};

export default parsePostDate;
