import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import { Helmet } from "react-helmet";

const LinkedInPosts = () => {
    return (
      <main className="w-full px-2 md:px-10">
        <Helmet>
          <title>LinkedIn | Cascade Clarity</title>
          <script
            src="https://static.elfsight.com/platform/platform.js"
            data-use-service-core
            defer
          ></script>
        </Helmet>
        <NavBar />
        <SubpageHero
          pageTitle={"LinkedIn"}
          testimonial={{
            quote:
              "\"Cascade Clarity provided a digital roadmap that helped us capture employee lifecycle changes and understand their financial impact. Previously, we lacked real-time visibility into key insights.\"",
            author: "Mike M. Chief Financial Officer",
          }}
        />
        <div
          className="elfsight-app-79ffec1e-f27b-4574-9a86-95aa7da99938 my-10"
          data-elfsight-app-lazy
        ></div>
        <Footer />
      </main>
    );
}

export default LinkedInPosts;