import { useParams } from "react-router-dom";

import NavBar from "components/Navbar";
import Footer from "components/Footer";
import PostContent from "./PostContent";
import { useEffect, useState } from "react";

const PostDetails = () => {
  const [blogListSpinner, setBlogListSpinner] = useState(true);
  const [blogListError, setBlogListError] = useState({
    error: false,
    errorMessage: "Something went wrong, please try again later :(",
  });
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setBlogListSpinner(true);
    try {
      const postResponse = await fetch(
        `${process.env.REACT_APP_BLOGS_ENDPOINT}/posts?slug=${slug}`,
        {
          method: "GET",
        },
      );

      const postData = await postResponse.json();
      if (postData.length < 1) {
        setBlogListError({
          error: true,
          errorMessage: "Something went wrong, please try again later :(",
        });
      } else {
        setPost(postData[0]);
      }
    } catch (error) {
      console.error("Error:", error);
      setBlogListError({ ...blogListError, error: true });
    } finally {
      setBlogListSpinner(false);
    }
  };
  return (
    <main className="min-h-[1000px] w-full px-2 md:px-10">
      <NavBar />
      {blogListSpinner && (
        <div className="fixed right-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-white bg-opacity-50">
          <div className="relative">
            <div className="h-[35vw] w-[35vw] rounded-full border-b-8 border-t-8 border-gray-200"></div>
            <div className="absolute left-0 top-0 h-[35vw] w-[35vw] animate-spin rounded-full border-b-8 border-t-8 border-brand-purple"></div>
          </div>
        </div>
      )}
      <PostContent content={post?.content?.rendered} />
      <Footer />
    </main>
  );
};

export default PostDetails;
