import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import Advisory from "./Advisory";
import ServiceList from "./ServiceList";
import CTABanner from "pages/Home/CTABanner";
import Connect from "pages/Home/Connect";
import ClientList from "pages/Home/ClientList";
import InfoText from "components/InfoText";
import { Helmet } from "react-helmet";

const Services = () => {
    return (
        <main className="w-full px-2 md:px-10">
            <Helmet>
                <title>Services | Cascade Clarity</title>
                <meta name="description" content="We've relied on Cascade Clarity principals for years to transform our strategic challenges into prioritized, actionable tech roadmaps. Their unique blend of C-suite vision and hands-on expertise consistently delivers outstanding results." />
            </Helmet>
            <NavBar />
            <SubpageHero pageTitle="Services"
                testimonial={{
                    quote: "\"Cascade Clarity principals helped us transform our strategic challenges into prioritized, actionable tech roadmaps. Their deep tech expertise ensured outcome alignment.\"",
                    author: "Chief People Officer: Florida Cancer Specialists"
                }}
            />
            {/* <InfoText
                title={"Tailored Solutions for Enhanced Business Performance"}
                subtitle={"Gain clarity with our comprehensive suite of services. We offer strategic and technical expertise in HR,\n Finance, and Security domains to help you improve business performance and achieve your goals. "}
            /> */}
            {/* <Advisory /> */}
            <section
        id="success-stories" className="my-0 flex w-full flex-col items-center justify-center gap-6 rounded-lg bg-brand-lightBlue bg-opacity-10 px-5 py-10 text-center"
      >
         <h2 className="text-4xl font-bold text-brand-purple">
         Modernize : Transform : Succeed
        </h2>
        <div className="max-w-4xl text-xl text-justify leading-relaxed">
        Our services encompass strategic business guidance and technology execution that bridge enterprise complexity with innovation. We lead with AI-driven possibilities, evaluating transformative opportunities across vendor agnostic platforms to solve enterprise challenges
        </div>
      </section>
            <ServiceList />
            {/* <CTABanner /> */}
            <Connect />
            {/* <ClientList /> */}
            <Footer />
        </main>
    );
}

export default Services;