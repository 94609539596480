const SuccessStoryCard = ({ story }) => {
  return (
    <div
      id={story.id}
      key={story.id}
      className={`${story.bgColorClass} flex h-full w-full flex-col justify-start gap-2 rounded-3xl px-2 py-6 lg:max-w-[84vw] lg:px-16 lg:py-16`}
    >
      <div className="flex flex-col items-start gap-10 lg:flex-row">
        <div className="mx-auto h-fit max-w-[100px] lg:mx-0">{story.icon}</div>
        <div className="-mt-2 h-fit text-center text-xl font-bold lg:text-left lg:text-2xl lg:leading-tight">
          {story.title}
          <div className="mb-4 ml-6 mt-2 max-w-[1000px] text-center text-xl font-bold lg:mb-2 lg:text-left lg:text-xl lg:leading-loose">
            <strong>
              Service Category:{"   "} &nbsp;
              <span className={`${story.textColorClass}`}>
                {story.category}
              </span>
            </strong>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 text-xl lg:grid-cols-1">
        <div className="rounded-3xl border border-gray-200 bg-white px-5 py-6 text-justify shadow-sm lg:px-12 lg:py-6">
          <div
            className={`mb-2 text-center font-bold lg:text-left ${story.textColorClass}`}
          >
            Problem Statement
          </div>
          {story._renderProblemContent()}
          <div
            className={`my-2 text-center font-bold lg:text-left ${story.textColorClass}`}
          >
            Solution
          </div>
          {story._renderDescriptionContent()}
          <div className="mt-10 flex flex-col items-center gap-5 lg:flex-row">
            <div className={`font-bold ${story.textColorClass}`}>Company</div>
            <div className="flex h-auto w-32 items-center justify-center rounded-lg border border-gray-300 bg-white p-2 transition-all duration-200 ease-in-out hover:scale-110">
              <img
                src={story.companyLogo}
                alt=""
                className="max-h-full max-w-full object-contain"
                width={"100%"}
                height={"100%"}
                loading="lazy"
              />
            </div>
          </div>
          <div
            className={`mb-2 mt-12 text-center font-bold lg:text-left ${story.textColorClass}`}
          >
            Technology Used
          </div>
          <div className="mx-auto flex flex-row flex-wrap justify-center gap-3 lg:justify-normal">
            {story.techLogos.map((techLogo, index) => {
              return (
                <div
                  key={techLogo.alt + index}
                  className="flex h-auto w-32 items-center justify-center rounded-lg border border-gray-300 bg-white p-2 transition-all duration-200 ease-in-out hover:scale-110"
                >
                  <img
                    src={techLogo.img}
                    alt={techLogo.alt}
                    className="max-h-full max-w-full object-contain"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoryCard;
