import PostList from "./PostList";

const Post = () => {

    return (
        <div className="max-w-[1000px] flex justify-center items-center ">
            <PostList />
        </div>
    );
}

export default Post;