export const successStories = [
  {
    id: "Global-HRIS-vs-Payroll",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <g clip-path="url(#clip0_496_282)">
          <path
            d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
            fill="url(#paint0_linear_496_282)"
          />
          <path
            d="M40 5C59.5 5 75 20.5 75 40C75 59.5 59.5 75 40 75C20.5 75 5 59.5 5 40C5 20.5 20.5 5 40 5ZM40 0C18 0 0 18 0 40C0 62 18 80 40 80C62 80 80 62 80 40C80 18 62 0 40 0Z"
            fill="url(#paint1_linear_496_282)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_496_282"
            x1="55.4237"
            y1="20"
            x2="38.44"
            y2="38.169"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7475FF" />
            <stop offset="1" stop-color="#6163F6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_496_282"
            x1="70.8475"
            y1="5.59949e-06"
            x2="36.8801"
            y2="36.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7475FF" />
            <stop offset="1" stop-color="#6163F6" />
          </linearGradient>
          <clipPath id="clip0_496_282">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title:
      <>Global HR Transformation: <br />Strategic Global HRIS versus Distributed Payroll</>,
    category: "Digital Strategy and Alignment",
    bgColorClass: "bg-brand-accentPurple bg-opacity-[6%]",
    textColorClass: "text-brand-accentPurple",
    companyLogo: require("assets/images/companies/afiniti.png"),
    techLogos: [
      {
        alt: "paycom",
        img: require("assets/images/tech-stack/paycom.png"),
      },
      {
        alt: "adp",
        img: require("assets/images/tech-stack/adp.png"),
      },
      {
        alt: "tableau",
        img: require("assets/images/tech-stack/tableau.png"),
      },
      {
        alt: "service-now",
        img: require("assets/images/tech-stack/servicenow.png"),
      },
    ],
    _renderProblemContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
          As our client scaled across borders, its patchwork of HR and payroll solutions created a tangled web of processes, compromising efficiency, data integrity, and strategic data governance. Their challenges included:
          </div>
          <ul className="mb-10 list-inside list-disc text-lg leading-relaxed marker:text-xs">
            <li>Inefficient operations</li>
            <li>Confusion about which system should do what</li>
            <li>Too many spreadsheets with sensitive information</li>
            <li>Poor control over data</li>
          </ul>
        </>
      );
    },
    _renderDescriptionContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
          We provided a digital roadmap that provided clarity between the distinct roles of Global HRIS i.e. 
          <span style={{ fontWeight: 'bold', color: 'red' }}>Strategic Data: </span>
          compensation change history, org. structure, etc. and payroll applications i.e. 
          <span style={{ fontWeight: 'bold', color: 'red' }}>Tactical Data:</span> earnings, deductions, compliance, etc. 
          Our activities and deliverables included:
          </div>
          <ul className="mb-10 list-inside list-disc text-lg leading-loose">
            <li>
              HR Transactions mapped to automation path
            </li>
            <li>RFP ready list of global HRIS requirements</li>
            <li>
              A new, simpler map of how their systems should work together
            </li>
            <li>
              Digital roadmap for HR Process Automation with AI
            </li>
          </ul>
        </>
      );
    },
  },
  {
    id: "AI-HR-Automation",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <g clip-path="url(#clip0_494_276)">
          <path
            d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
            fill="url(#paint0_linear_494_276)"
          />
          <path
            d="M40 5C59.5 5 75 20.5 75 40C75 59.5 59.5 75 40 75C20.5 75 5 59.5 5 40C5 20.5 20.5 5 40 5ZM40 0C18 0 0 18 0 40C0 62 18 80 40 80C62 80 80 62 80 40C80 18 62 0 40 0Z"
            fill="url(#paint1_linear_494_276)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_494_276"
            x1="55.0442"
            y1="20"
            x2="41.7367"
            y2="60.5697"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#67E5FF" />
            <stop offset="1" stop-color="#3EC4E0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_494_276"
            x1="70.0885"
            y1="-5.4761e-07"
            x2="43.4734"
            y2="81.1393"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#67E5FF" />
            <stop offset="1" stop-color="#3EC4E0" />
          </linearGradient>
          <clipPath id="clip0_494_276">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title:
      <>Streamlining HR OPS with AI: <br /> Automated Employment Verification Letters</>,
    category: "AI Innovation and Adoption",
    bgColorClass: "bg-brand-accentBlue bg-opacity-[6%]",
    textColorClass: "text-brand-accentBlue",
    _renderProblemContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
            Our client's process inefficiencies included a time-consuming, manual and labor intensive
            process for creating employment verification letters, impacting HR
            operations across global locations and hindering their automation
            and cost reduction efforts.
          </div>
        </>
      );
    },
    _renderDescriptionContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
            We developed a digital road map and proof of concept leveraging  AI and open source integration tools to automate this process.
            Because of the sensitivity of the data, we deployed an on-premise, open source LLM model and integrated that to internal legacy systems. 
            The solution was front ended with a user-friendly interface. Our solution made use of the following technology: {" "}
          </div>
          <ul className="mb-10 list-inside list-disc text-lg leading-relaxed marker:text-xs">
            <li>Open source AI model</li>
            <li>LangChain for AI/Legacy data interconnectivity</li>
            <li>A user-friendly interface</li>
            <li>Seamless integration with existing systems</li>
          </ul>
        </>
      );
    },
    companyLogo: require("assets/images/companies/afiniti.png"),
    techLogos: [
      {
        alt: "mistral",
        img: require("assets/images/tech-stack/mistral.png"),
      },
      {
        alt: "langchain",
        img: require("assets/images/tech-stack/langchain.png"),
      },
      {
        alt: "paycom",
        img: require("assets/images/tech-stack/paycom.png"),
      },
      {
        alt: "tableau",
        img: require("assets/images/tech-stack/tableau.png"),
      },
    ],
  },
  {
    id: "HR-IT-Integration",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <g clip-path="url(#clip0_496_286)">
          <path
            d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
            fill="url(#paint0_linear_496_286)"
          />
          <path
            d="M40 5C59.5 5 75 20.5 75 40C75 59.5 59.5 75 40 75C20.5 75 5 59.5 5 40C5 20.5 20.5 5 40 5ZM40 0C18 0 0 18 0 40C0 62 18 80 40 80C62 80 80 62 80 40C80 18 62 0 40 0Z"
            fill="url(#paint1_linear_496_286)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_496_286"
            x1="55.4237"
            y1="20"
            x2="38.44"
            y2="38.169"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7475FF" />
            <stop offset="1" stop-color="#6163F6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_496_286"
            x1="70.8475"
            y1="5.59949e-06"
            x2="36.8801"
            y2="36.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#7475FF" />
            <stop offset="1" stop-color="#6163F6" />
          </linearGradient>
          <clipPath id="clip0_496_286">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title:
      "Automating HR and IT Processes via Employee Lifecycle Transactions",
    category: "Enterprise Implementation",
    bgColorClass: "bg-brand-accentPurple bg-opacity-[6%]",
    textColorClass: "text-brand-accentPurple",
    companyLogo: require("assets/images/companies/wuxi.png"),
    techLogos: [
      {
        alt: "synchr",
        img: require("assets/images/tech-stack/synchr-blue.png"),
      },
      {
        alt: "mulesoft",
        img: require("assets/images/tech-stack/mulesoft.png"),
      },
      {
        alt: "servicenow",
        img: require("assets/images/tech-stack/servicenow.png"),
      },
      {
        alt: "activeMQ",
        img: require("assets/images/tech-stack/activemq.png"),
      },
    ],
    _renderProblemContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
          Cascade Clarity principals were engaged to help streamline manual processes between HR & IT. The client recognized the similarity in
            the data and processes between the two departments when it came to employee lifecycle transactions.  
            For example, Onboarding & offboarding employees, changing roles, and updating personal information had similar impacts on HR and IT systems
            albeit with different purposes:
            <ol className="list-decimal ml-6 mt-4 mb-4">
              <li>HRIS for strategic employee information and organization relationships</li>
              <li>IT for maintaining access control across the organization</li>
            </ol>
            Redundant processes for identical employee transactions resulted in inefficiencies, delays, and frequent errors.
          </div>
        </>
      );
    },
    _renderDescriptionContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
            We took a close look at their digital setup and created a plan to
            fix it. We:
          </div>
          <ol className="mb-10 list-inside list-disc text-lg leading-loose">
            <li>Installed SyncHR, a cloud-based HR and payroll system</li>
            <li>
              Used MuleSoft to orchestrate employee transactions across systems.
            </li>
            <li>
              Implemented "guarenteed delivery" for all transactions
            </li>
            <li>Trained technical team for on-going maintenance and ownership</li>
          </ol>
        </>
      );
    },
  },
  {
    id: "Strategic-Dashboard-Analytics",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <g clip-path="url(#clip0_496_290)">
          <path
            d="M40 20C29 20 20 29 20 40C20 51 29 60 40 60C51 60 60 51 60 40C60 29 51 20 40 20Z"
            fill="url(#paint0_linear_496_290)"
          />
          <path
            d="M40 5C59.5 5 75 20.5 75 40C75 59.5 59.5 75 40 75C20.5 75 5 59.5 5 40C5 20.5 20.5 5 40 5ZM40 0C18 0 0 18 0 40C0 62 18 80 40 80C62 80 80 62 80 40C80 18 62 0 40 0Z"
            fill="url(#paint1_linear_496_290)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_496_290"
            x1="52.2124"
            y1="20"
            x2="41.4581"
            y2="60.3883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5EEACB" />
            <stop offset="1" stop-color="#33BD9E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_496_290"
            x1="64.4248"
            y1="-6.27542e-07"
            x2="42.9162"
            y2="80.7765"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5EEACB" />
            <stop offset="1" stop-color="#33BD9E" />
          </linearGradient>
          <clipPath id="clip0_496_290">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title:
      <>Strategic Dashboards for Executives: <br />Real-Time Business Insights at Their Fingertips</>,
    category: "Digital Strategy and Alignment",
    bgColorClass: "bg-brand-cyan bg-opacity-[6%]",
    textColorClass: "text-brand-cyan",
    companyLogo: require("assets/images/companies/fcl.png"),
    techLogos: [
      {
        alt: "SyncHR",
        img: require("assets/images/tech-stack/synchr-blue.png"),
      },
      {
        alt: "mosaic",
        img: require("assets/images/tech-stack/mosaic.png"),
      },
      {
        alt: "Netsuite",
        img: require("assets/images/tech-stack/netsuite.png"),
      },
      {
        alt: "muelsoft",
        img: require("assets/images/tech-stack/mulesoft.png"),
      },
    ],
    _renderProblemContent: () => {
      return (
        <>
          <div className="mb-8 text-lg leading-relaxed">
          Cascade Clarity principals were engaged to create a single pane of glass to view real time business metrics.
          They needed the following for strategic decision making:
          </div>
          <ul className="mb-10 list-inside list-disc text-lg leading-relaxed marker:text-xs">
            <li>Employee headcount at company, location and organization level</li>
            <li>Employee & Contractor budget to actual spend</li>
            <li>Hiring/Turnover rates at company, location and organization level</li>
            <li>'What if' modeling for strategic planning</li>
          </ul>
          <div className="mt-8 text-lg leading-relaxed">
            The challenge was bringing together information from different systems and making it easy to understand. We performed the following activities:
          </div>
        </>
      );
    },
    _renderDescriptionContent: () => {
      return (
        <>
          <ol className="mb-10 list-inside list-disc text-lg leading-loose">

            <li>Worked with executives to gather requirements for dashboards</li>
            <li>
              Deployed advanced visualization and dashboarding software
            </li>
            <li>Orchestrated Employee Life Cycle transactions across systems</li>
            <li>
              Provided training to executives on how to use the new dashboards
            </li>
          </ol>
        </>
      );
    },
  },
];
