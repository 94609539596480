import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import PostList from "pages/Post/PostList";

const Articles = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <NavBar />
      <SubpageHero
        pageTitle={"Articles"}
        testimonial={{
          quote:
            "Cascade Clarity delivers extraordinary value by bringing executive level acumen and focus through to strategic technical execution",
          author: "Pam G. CEO LumenaLabs Inc.",
        }}
      />
      <PostList />
      <Footer />
    </main>
  );
};

export default Articles;
