import Footer from "components/Footer";
import InfoText from "components/InfoText";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import HowWeDoit from "pages/Home/HowWeDoIt";
import VisualLayer from "./VisualLayer";
import ProcessList from "./ProcessList";
import CTABanner from "pages/Home/CTABanner";
import Connect from "pages/Home/Connect";
import ClientList from "pages/Home/ClientList";
import { Helmet } from "react-helmet";
import HowWeDoitSection from "./HowWeDoItSection";

const HowWeDoIt = () => {
    return (
        <main className="w-full px-2 md:px-10">
            <Helmet>
                <title>How We Do It | Cascade Clarity</title>
            </Helmet>
            <NavBar />
            <SubpageHero pageTitle={"How We Do It"} />
            <InfoText
                title={"Comprehensive Digital Transformation Solutions"}
                subtitle={"At Cascade Clarity, we guide companies through the challenges of digital transformation, focusing on HR,\n Finance, and Security. Our unique approach blends precise management consulting with deep technical\n expertise, helping your business achieve strategic goals with advanced technological solutions. "}
            />
            <HowWeDoitSection />
            <VisualLayer />
            <ProcessList />
            <CTABanner />
            <Connect />
            <ClientList />
            <Footer />
        </main>
    );
}

export default HowWeDoIt;