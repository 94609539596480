const ServiceBlock = ({ id, icon, title, description }) => {
    return (
        <div id={id} className="grid grid-cols-5   gap-10 items-center w-full">
            <div className="col-span-2  flex flex-col gap-10">
                {icon}
                <h2 className="font-bold text-6xl">
                    {title}
                </h2>
            </div>
            <p className="col-span-3  text-xl text-pretty">
                {description}
            </p>
        </div>
    );
}

export default ServiceBlock;