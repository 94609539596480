import NavBar from "components/Navbar";
import VisualLayerSlider from "components/VisualLayerSlider";
import Hero from "./Hero";
import HowWeDoit from "./HowWeDoIt";
import Services from "./Services";
import TechStack from "./TechStack";
import Connect from "./Connect";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <Helmet>
        <title>Home | Cascade Clarity</title>
      </Helmet>
      <NavBar />
      <Hero />
      <HowWeDoit />
      <VisualLayerSlider />
      <Services />
      <TechStack />
      <Connect downloadSection />
      <Footer />
    </main>
  );
};

export default HomePage;
