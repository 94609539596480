import SuccessStoryCard from "./SuccessStoryCard";
import { successStories } from "./data";

const SuccessStoriesList = () => {
  return (
    <section className="mb-10 grid w-full grid-cols-1 items-center justify-center gap-5 px-1 lg:w-fit lg:grid-cols-2 lg:gap-3 lg:px-10">
      {successStories.map((story, index) => {
        return (
          <>
            <SuccessStoryCard story={story} key={story.id} />
          </>
        );
      })}
    </section>
  );
};

export default SuccessStoriesList;
