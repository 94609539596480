import Footer from "components/Footer";
import InfoText from "components/InfoText";
import NavBar from "components/Navbar";
import ServiceBlockLogos from "components/ServiceBlockLogos";
import SubpageHero from "components/SubpageHero";
import CTABanner from "pages/Home/CTABanner";
import ClientList from "pages/Home/ClientList";
import Connect from "pages/Home/Connect";
import TechStackList from "./TechStackList";
import { Helmet } from "react-helmet";

const TechStack = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <Helmet>
        <title>Techology | Cascade Clarity</title>
      </Helmet>
      <NavBar />
      <SubpageHero
        pageTitle={"Technology"}
        testimonial={{
          quote:
            "\"Cascade principals' integration of SyncHR HRIS with Azure AD streamlined IT processes, enhanced security, and automated SOC controls. Their webhook expertise and guaranteed transactions eliminated manual HR-IT processes\"",
          author: "Chief Information Officer: Wuxi AppTec",
        }}
      />
      {/* <InfoText
                title={"Cascade Clarity offers vendor-agnostic solutions, favoring open-source technologies. We address business challenges using\n your existing tech stack, supplemented with select third-party components as needed. \nThis maximizes your current investments while introducing innovative technologies for optimal impact."}
                subtitle={""}
            /> */}
      <section
        id="why-us"
        className="my-0 flex w-full flex-col items-center justify-center gap-6 rounded-lg bg-brand-lightBlue bg-opacity-10 px-5 py-10 text-center"
      >
        <h2 className="text-4xl font-bold text-brand-purple">
          Our Technology Position
        </h2>
        <div className="max-w-4xl text-xl leading-relaxed">
        Cascade Clarity is vendor-agnostic and favors open-source technology in its solutions. We build on your tech stack, adding third-party components where needed to maximize value and innovation.
        </div>
      </section>
      <TechStackList />
      {/* <CTABanner /> */}
      <Connect />
      {/* <ClientList /> */}
      <Footer />
    </main>
  );
};

export default TechStack;
