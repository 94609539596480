import PrimaryLogo from "components/PrimaryLogo";
import useScrollWithOffset from "hooks/useScrollWithOffset";
import { HashLink } from "react-router-hash-link";
import { Twirl as Hamburger } from "hamburger-react";
import { useState } from "react";
import MobileMenu from "./MobileMenu";
import { naviagtionData } from "./navigationData";

const NavBar = () => {
  const scrollWithOffset = useScrollWithOffset();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleMenuHover = (event, id) => {
    const submenu = document.getElementById(`nav-submenu-${id}`);
    const arrow = document.getElementById(`nav-menu-arrow-${id}`);
    const isValidNode = (node) => node instanceof Node;

    if (
      event.type === "mouseleave" &&
      (!isValidNode(event.relatedTarget) ||
        !submenu.contains(event.relatedTarget))
    ) {
      submenu.style.display = "none";
      arrow.style.transform = "scaleY(1)";
      arrow.style.fill = "#6163F6";
    } else {
      submenu.style.display = "block";
      arrow.style.transform = "scaleY(-1)";
      arrow.style.fill = "#fff";
    }
  };

  return (
    <>
      <div
        className="sticky left-0 top-2 z-50 mx-auto mb-3 mt-2 flex h-[90px] w-auto flex-row items-center justify-center rounded-[20px] bg-brand-darkGrey px-5 md:h-[120px] md:px-10"
        id="nav-bar"
        onScroll={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href="/"
          className="mr-auto flex h-fit w-full max-w-[170px] items-center justify-center"
        >
          <PrimaryLogo />
        </a>
        <nav className="text-md ml-auto hidden h-full w-full max-w-[75%] flex-row items-center justify-between gap-5 text-white lg:flex">
          {naviagtionData.map((item) =>
            item.submenu ? (
              <div
                key={item.id}
                onMouseOver={(event) => handleMenuHover(event, item.id)}
                onMouseLeave={(event) => handleMenuHover(event, item.id)}
                // The padding plays a part in detecting the mouse events, take precaution in changing this.
                className="py-2"
              >
                <div className="relative flex cursor-pointer flex-row items-center justify-center gap-3 uppercase tracking-wide 2xl:gap-8">
                  <a href={item.link} style={{ textDecoration: "none" }}>
                    <span className="font-bold tracking-wider hover:text-brand-purple">
                      {item.name}
                    </span>
                  </a>
                  <svg
                    id={`nav-menu-arrow-${item.id}`}
                    className="hidden transform fill-brand-purple transition-transform duration-150 ease-in-out xl:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                  >
                    <path d="M7 11L13.0622 0.5H0.937822L7 11Z" />
                  </svg>
                  <div
                    id={`nav-submenu-${item.id}`}
                    className="absolute right-0 top-8 hidden w-fit rounded-md bg-brand-darkGrey px-7 py-5 transition-all duration-150 ease-in-out"
                  >
                    {item.submenu.map((subItem) => (
                      <HashLink
                        scroll={(el) => scrollWithOffset(el)}
                        smooth
                        key={subItem.id}
                        to={subItem.link}
                        style={{ textDecoration: "none" }}
                        className="block text-nowrap py-2 tracking-wider text-white transition-all duration-150 ease-in-out hover:text-brand-purple"
                      >
                        {subItem.name}
                      </HashLink>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <HashLink
                scroll={(el) => scrollWithOffset(el)}
                smooth
                key={item.id}
                className="py-2"
                to={item.link}
              >
                <div className="relative flex cursor-pointer flex-row items-center justify-center gap-8 uppercase tracking-wide hover:text-brand-purple">
                  <span className="">{item.name}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                  >
                    <path d="M7 11L13.0622 0.5H0.937822L7 11Z" fill="#6163F6" />
                  </svg>
                </div>
              </HashLink>
            ),
          )}
        </nav>
        <div className="cursor-pointer overflow-hidden rounded-full text-white transition-all duration-500 lg:hidden">
          <Hamburger
            color={"#fff"}
            size={24}
            toggled={isSidebarOpen}
            toggle={setIsSidebarOpen}
          />
        </div>
      </div>
      <MobileMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        naviagtionData={naviagtionData}
      />
    </>
  );
};

export default NavBar;
