const PrimaryLogo = () => {
  return (
    <img
      src={require("assets/logo.png")}
      alt="Cascade Clarity"
      width={"100%"}
      height={"100%"}
      className="!m-0"
    />
  );
};

export default PrimaryLogo;
