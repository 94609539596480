import useScrollWithOffset from "hooks/useScrollWithOffset";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const MobileMenu = ({ isSidebarOpen, setIsSidebarOpen, naviagtionData }) => {
    const scrollWithOffset = useScrollWithOffset();
    const handleMenuClick = (event, id) => {
        event.stopPropagation();

        // Select all submenus using a common class or attribute
        const submenus = document.querySelectorAll('.mobile-nav-submenu');
        const arrows = document.querySelectorAll('.mobile-nav-menu-arrow');

        // Hide all submenus (except the clicked one) and apply arrow styling
        for (let i = 0; i < submenus.length; i++) {
            const submenu = submenus[i];
            const arrow = arrows[i];

            if (submenu.id !== `mobile-nav-submenu-${id}`) {
                submenu.style.display = 'none';
            }
            if (arrow.id !== `mobile-nav-menu-arrow-${id}`) {
                arrow.style.transform = "scaleY(1)";
                arrow.style.fill = "#6163F6";
            }
        }

        // Select the intended submenu
        const submenu = document.getElementById(`mobile-nav-submenu-${id}`);

        // Check if the submenu is already open
        const isOpen = submenu.style.display === 'block';

        // Toggle visibility based on current state
        submenu.style.display = isOpen ? 'none' : 'block';

        // Update the arrow for the intended submenu (optional)
        if (!isOpen) { // Update arrow only if submenu is being opened
            const arrow = document.getElementById(`mobile-nav-menu-arrow-${id}`);
            arrow.style.transform = "scaleY(-1)";
            arrow.style.fill = "white";
        } else {
            // Update arrow for closing (change styles if needed)
            const arrow = document.getElementById(`mobile-nav-menu-arrow-${id}`);
            arrow.style.transform = "scaleY(1)";
            arrow.style.fill = "#6163F6";
        }
    };
    return (
        <div className={` w-[95%] rounded-[20px] max-h-[calc(100vh-100px)] overflow-y-auto overscroll-none h-full pb-4 fixed lg:hidden bg-brand-darkGrey opacity-100 z-50 transition-all duration-300 ease-in-out ${isSidebarOpen ? "right-2" : "-right-[110%]"}`}>
            {isSidebarOpen && (
                <div className="py-5 ">
                    <nav className="flex flex-col text-md text-white gap-5 h-full pr-5 pl-7 ">
                        {naviagtionData.map((item) => (
                            item.submenu ? (
                                <div key={item.id}
                                    onClick={(event) => handleMenuClick(event, item.id)}
                                    // The padding plays a part in detecting the mouse events, take precaution in changing this.
                                    className="py-2"
                                >
                                    <div className="relative  flex flex-col gap-3 uppercase tracking-wide cursor-pointer justify-center w-72 mx-auto">
                                        <div className="flex flex-row items-center gap-10 justify-between ">
                                            <a href={item.link}
                                                onClick={() => setIsSidebarOpen(false)}
                                            >
                                                <span className="hover:text-brand-purple font-bold tracking-wider">{item.name}</span>
                                            </a>
                                            <svg
                                                onClick={(event) => handleMenuClick(event, item.id)}
                                                id={`mobile-nav-menu-arrow-${item.id}`}
                                                className=" mobile-nav-menu-arrow fill-brand-purple transform transition-transform duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                                <path d="M7 11L13.0622 0.5H0.937822L7 11Z" />
                                            </svg>
                                        </div>
                                        <div id={`mobile-nav-submenu-${item.id}`} className="hidden mobile-nav-submenu pl-3 transition-all duration-150 ease-in-out w-fit bg-brand-darkGrey bg-opacity-20 rounded-md ">
                                            {item.submenu.map((subItem) => (
                                                <HashLink
                                                    scroll={el => scrollWithOffset(el)}
                                                    onClick={() => setIsSidebarOpen(false)}
                                                    smooth key={subItem.id} to={subItem.link} className="tracking-wider block text-white py-2 text-wrap hover:text-brand-purple transition-all duration-150 ease-in-out">{subItem.name}</HashLink>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <HashLink
                                    scroll={el => scrollWithOffset(el)}
                                    smooth
                                    key={item.id}
                                    onClick={() => setIsSidebarOpen(false)}
                                    className="py-2"
                                    to={item.link}>
                                    <div className="relative flex flex-row gap-8 items-center justify-center uppercase tracking-wide cursor-pointer hover:text-brand-purple">
                                        <span>{item.name}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                            <path d="M7 11L13.0622 0.5H0.937822L7 11Z" fill="#6163F6" />
                                        </svg>
                                    </div>
                                </HashLink>
                            )
                        ))}
                    </nav>

                </div>
            )}
        </div>
    );
};

export default MobileMenu;
