const WhyUsBlock = ({ icon, title, description }) => {
    return (
        <div className="flex flex-col gap-7">
            <div className="flex flex-row gap-7 items-center">
                <div>{icon}</div>
                <h3 className="font-bold text-xl">{title}</h3>
            </div>
            <p className="text-lg text-pretty text-justify">{description}</p>
        </div>
    );
}

export default WhyUsBlock;