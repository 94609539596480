const VisualLayer = () => {
    return (
        <section id='visual-layer' className="my-44 flex flex-row gap-10 max-w-[1400px] px-20 mx-auto">
            <div id="digital-transformation" className="flex flex-col gap-10 w-1/2">
                <div className="flex flex-col gap-10 w-3/5 ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="78" viewBox="0 0 72 78" fill="none">
                        <rect width="72" height="77.0973" rx="9" fill="url(#paint0_linear_21_529)" />
                        <path d="M29.5132 35.0726C29.9365 35.0726 30.3591 35.017 30.768 34.9063C31.0239 34.8369 31.2738 34.746 31.5146 34.6353C31.4703 34.335 31.4481 34.0319 31.4481 33.7283C31.4481 32.0932 32.0919 30.5395 33.2479 29.3833C33.4939 29.1373 33.7605 28.9124 34.0442 28.7113C33.7395 27.8293 33.1842 27.057 32.4448 26.4859C31.604 25.8362 30.5755 25.4868 29.5132 25.4868C28.2375 25.4868 27.0258 25.9887 26.124 26.8905C25.222 27.7924 24.7202 29.0041 24.7202 30.2796C24.7202 31.5552 25.2224 32.7669 26.124 33.6688C27.0258 34.5708 28.2375 35.0726 29.5132 35.0726Z" fill="white" />
                        <path d="M34.2041 37.1173C35.1059 38.0193 36.3177 38.5211 37.5933 38.5211C38.869 38.5211 40.0806 38.0193 40.9826 37.1173C41.8846 36.2155 42.3863 35.0038 42.3863 33.7282C42.3863 32.4526 41.8844 31.241 40.9826 30.339C40.0808 29.437 38.869 28.9352 37.5933 28.9352C36.3177 28.9352 35.1061 29.437 34.2041 30.339C33.3021 31.2408 32.8003 32.4526 32.8003 33.7282C32.8003 35.0039 33.3022 36.2155 34.2041 37.1173Z" fill="white" />
                        <path d="M43.7376 33.7281C43.7376 34.0316 43.7155 34.3348 43.6711 34.6351C43.9119 34.7459 44.1618 34.8367 44.4178 34.9061C44.8265 35.0167 45.2491 35.0724 45.6724 35.0724C46.9481 35.0724 48.1598 34.5705 49.0617 33.6687C49.9636 32.7667 50.4655 31.5551 50.4655 30.2795C50.4655 29.0038 49.9637 27.7923 49.0617 26.8903C48.1599 25.9884 46.9481 25.4867 45.6724 25.4867C44.61 25.4867 43.5814 25.8361 42.7408 26.4858C42.0015 27.0571 41.4461 27.8295 41.1414 28.7113C41.425 28.9124 41.6918 29.1374 41.9377 29.3833C43.0938 30.5392 43.7376 32.0928 43.7376 33.728L43.7376 33.7281Z" fill="white" />
                        <path d="M51.3578 37.8024C49.6952 36.8219 47.5838 36.4244 45.6728 36.4244C45.1621 36.4244 44.6505 36.4518 44.1427 36.5092C43.7511 36.5534 43.3611 36.6153 42.975 36.6957C42.6958 37.2009 42.3462 37.6652 41.938 38.0732C41.6273 38.3839 41.284 38.6609 40.9148 38.8986C41.9756 39.1492 43.0278 39.5361 43.9646 40.0887C45.4649 40.9735 46.7943 42.3853 46.7943 44.225V49.1819C47.6627 49.1414 48.5331 49.0534 49.3894 48.904C50.1362 48.7738 50.8911 48.595 51.6045 48.3369C52.0905 48.1612 53.5222 47.5916 53.5222 46.9329V40.7762C53.5222 39.452 52.4155 38.4258 51.3579 37.8023L51.3578 37.8024Z" fill="white" />
                        <path d="M43.2777 41.2506C41.6151 40.2701 39.5039 39.8728 37.5928 39.8728C35.6816 39.8728 33.5703 40.2701 31.9078 41.2506C30.8503 41.8743 29.7435 42.9003 29.7435 44.2246V50.3813C29.7435 51.0705 31.2772 51.6543 31.7841 51.8296C32.6187 52.1185 33.5083 52.3056 34.3814 52.4325C35.4422 52.5862 36.5213 52.6542 37.593 52.6542C38.6647 52.6542 39.7437 52.5862 40.8046 52.4325C41.6777 52.3056 42.5673 52.1183 43.4019 51.8296C43.9087 51.6542 45.4424 51.0704 45.4424 50.3813V44.2246C45.4424 42.9004 44.3357 41.8742 43.2781 41.2506H43.2777Z" fill="white" />
                        <path d="M31.2213 40.0885C32.1582 39.536 33.2103 39.1491 34.2713 38.8985C33.9021 38.6608 33.5586 38.384 33.2479 38.0731C32.8399 37.6651 32.4903 37.2008 32.2111 36.6956C31.8249 36.6151 31.435 36.5533 31.0432 36.5091C30.5355 36.4517 30.0241 36.4243 29.5132 36.4243C27.6021 36.4243 25.4907 36.8218 23.8283 37.8023C22.7708 38.4258 21.6638 39.4519 21.6638 40.7762V46.9329C21.6638 47.5917 23.0956 48.1612 23.5815 48.3368C24.295 48.5949 25.0498 48.7737 25.7965 48.904C26.6528 49.0533 27.5233 49.1412 28.3917 49.1818V44.2249C28.3919 42.3852 29.7212 40.9733 31.2215 40.0886L31.2213 40.0885Z" fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_21_529" x1="63.7627" y1="5.39632e-06" x2="30.9532" y2="32.7786" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7475FF" />
                                <stop offset="1" stop-color="#6163F6" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <h2 className="font-bold text-6xl">
                        The Visual Layer
                    </h2>
                </div>
                <p className="text-xl text-pretty max-w-[600px] leading-loose">
                    We understand that visualizing your data and processes is key to understanding and improving them. Our solutions create clear, actionable visualizations that help you see the big picture and make informed decisions.
                </p>
            </div>
            <div className='w-1/2'>
                <img src={require('assets/Dashboard-3.png')} alt="" />
            </div>
        </section>
    );
}

export default VisualLayer;