import CTAButton from "components/CTAButton";

const Connect = ({ downloadSection = false }) => {
  return (
    <section className="my-20 w-full">
      <div className="mx-auto flex w-full max-w-[1500px] flex-col gap-5 px-3 lg:flex-row lg:gap-16 lg:px-0">
        {downloadSection && (
          <div className="flex h-auto w-full flex-col gap-10 rounded-[20px] bg-brand-lightBlue px-[8%] py-10 lg:w-1/3 lg:px-[3.5%]">
        <div className="text-5xl font-bold">Download</div>
            <div className="text-pretty text-lg">
              Our GLOBAL HRIS vs Payroll analysis will be available soon. Check
              back here to download content{" "}
            </div>
            <CTAButton
              btnText="Coming Soon"
              href="#"
              hasArrow={false}
              opensNewTab={false}
              customBgColorClass="bg-gray-500 opacity-70 !pointer-events-none"
            />
          </div>
        )}
        <div className="flex h-auto w-full flex-row gap-10 rounded-[20px] bg-brand-lightGreen px-[8%] py-10 lg:px-[5%]">
          <div className="flex flex-col gap-7 lg:gap-4">
            <div className="text-5xl font-bold">Let's Connect</div>
            <div className="my-auto text-pretty text-lg">
              Discover how Cascade Clarity can illuminate the path to digital
              transformation for your B2B SaaS company. Contact us now to
              explore a partnership designed to elevate your business to new
              heights.
            </div>
            <div className="mt-auto">
              <CTAButton
                btnText="Get in touch"
                href="/about-us#connect-with-us"
                hasArrow
                opensNewTab={false}
              />
            </div>
          </div>
          <div className="hidden h-full w-fit items-center justify-center lg:block">
            <img
              src={require("assets/images/home/connect.png")}
              alt=""
              className="max-w-[240px] object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Connect;
