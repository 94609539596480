import AboutUs from "pages/AboutUs";
import Articles from "pages/Articles";
import HomePage from "pages/Home";
import HowWeDoIt from "pages/HowWeDoIt";
import Leadership from "pages/Leadership";
import LinkedInPosts from "pages/LinkedInPosts";
import PageNotFound from "pages/PageNotFound";
import Post from "pages/Post";
import PostDetails from "pages/Post/PostDetails";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Services from "pages/Services";
import SuccessStories from "pages/SuccessStories";
import TechStack from "pages/TechStack";
import { Routes, Route } from "react-router-dom";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/how-we-do-it" element={<HowWeDoIt />} />
      <Route path="/technology" element={<TechStack />} />
      <Route path="/success-stories" element={<SuccessStories />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route path="/blog/articles" element={<Articles />} />
      <Route path="/blog/articles/post/:slug" element={<PostDetails />} />
      <Route path="/blog/linkedin" element={<LinkedInPosts />} />
      <Route path="/post" element={<Post />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteList;
