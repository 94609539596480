import WhyUsBlock from "./WhyUsBlock";

const WhyUsGrid = () => {
  const whyUsGridData = [
    {
      id: 1,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_493_246)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_493_246)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_493_246)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_493_246"
              x1="44.7235"
              y1="16.25"
              x2="33.9111"
              y2="49.2129"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#67E5FF" />
              <stop offset="1" stop-color="#3EC4E0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_493_246"
              x1="56.9469"
              y1="-4.44933e-07"
              x2="35.3221"
              y2="65.9257"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#67E5FF" />
              <stop offset="1" stop-color="#3EC4E0" />
            </linearGradient>
            <clipPath id="clip0_493_246">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We Speak Your Language",
      description:
        "We understand that every business is unique. That's why we take the time to learn about your specific challenges and goals. We don't use fancy jargon or confusing tech-speak. Instead, we explain things in plain, simple terms that everyone in your organization can understand and act on.",
    },
    {
      id: 2,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_493_250)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_493_250)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_493_250)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_493_250"
              x1="45.0318"
              y1="16.25"
              x2="31.2325"
              y2="31.0123"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7475FF" />
              <stop offset="1" stop-color="#6163F6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_493_250"
              x1="57.5636"
              y1="4.54958e-06"
              x2="29.965"
              y2="29.5246"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7475FF" />
              <stop offset="1" stop-color="#6163F6" />
            </linearGradient>
            <clipPath id="clip0_493_250">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We Break Down Silos",
      description:
        'Over the years, we\'ve seen how departments can become isolated, leading to inefficiency and miscommunication. We call this the "silo effect." Our team has developed proven strategies to break down these barriers, encouraging collaboration and shared purpose across your entire organization.',
    },
    {
      id: 3,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_494_258)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_494_258)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_494_258)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_494_258"
              x1="45.0318"
              y1="16.25"
              x2="31.2325"
              y2="31.0123"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7475FF" />
              <stop offset="1" stop-color="#6163F6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_494_258"
              x1="57.5636"
              y1="4.54958e-06"
              x2="29.965"
              y2="29.5246"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#7475FF" />
              <stop offset="1" stop-color="#6163F6" />
            </linearGradient>
            <clipPath id="clip0_494_258">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We Harness the Power of Data",
      description:
        "In today's world, data is king. But it's not just about collecting data – it's about understanding it and using it to make smart decisions. We help you gather the right data, analyze it effectively, and turn it into actionable insights. This means you're not just guessing – you're making informed choices based on real evidence.",
    },
    {
      id: 4,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_494_254)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_494_254)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_494_254)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_494_254"
              x1="42.4226"
              y1="16.25"
              x2="33.6847"
              y2="49.0655"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5EEACB" />
              <stop offset="1" stop-color="#33BD9E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_494_254"
              x1="52.3451"
              y1="-5.09878e-07"
              x2="34.8694"
              y2="65.6309"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5EEACB" />
              <stop offset="1" stop-color="#33BD9E" />
            </linearGradient>
            <clipPath id="clip0_494_254">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We Simplify the Complex",
      description:
        "Business processes can often become overly complicated over time. We're experts at cutting through the clutter and simplifying these processes. This doesn't just make things easier – it also helps everyone in your organization understand their role better and work more efficiently.",
    },
    {
      id: 5,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_494_262)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_494_262)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_494_262)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_494_262"
              x1="42.4226"
              y1="16.25"
              x2="33.6847"
              y2="49.0655"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5EEACB" />
              <stop offset="1" stop-color="#33BD9E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_494_262"
              x1="52.3451"
              y1="-5.09878e-07"
              x2="34.8694"
              y2="65.6309"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5EEACB" />
              <stop offset="1" stop-color="#33BD9E" />
            </linearGradient>
            <clipPath id="clip0_494_262">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We're Focused on Results",
      description:
        "At the end of the day, what matters most are results. That's why we set clear, measurable goals and track progress every step of the way. We're not satisfied until we see real, tangible improvements in your business.",
    },
    {
      id: 6,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
        >
          <g clip-path="url(#clip0_494_266)">
            <path
              d="M32.5 16.25C23.5625 16.25 16.25 23.5625 16.25 32.5C16.25 41.4375 23.5625 48.75 32.5 48.75C41.4375 48.75 48.75 41.4375 48.75 32.5C48.75 23.5625 41.4375 16.25 32.5 16.25Z"
              fill="url(#paint0_linear_494_266)"
            />
            <path
              d="M32.5 4.0625C48.3438 4.0625 60.9375 16.6562 60.9375 32.5C60.9375 48.3438 48.3438 60.9375 32.5 60.9375C16.6562 60.9375 4.0625 48.3438 4.0625 32.5C4.0625 16.6562 16.6562 4.0625 32.5 4.0625ZM32.5 0C14.625 0 0 14.625 0 32.5C0 50.375 14.625 65 32.5 65C50.375 65 65 50.375 65 32.5C65 14.625 50.375 0 32.5 0Z"
              fill="url(#paint1_linear_494_266)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_494_266"
              x1="42.135"
              y1="17.593"
              x2="35.2785"
              y2="40.1625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#AC6BFF" />
              <stop offset="1" stop-color="#8756DE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_494_266"
              x1="51.7699"
              y1="2.68595"
              x2="38.0569"
              y2="47.825"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#AC6BFF" />
              <stop offset="1" stop-color="#8756DE" />
            </linearGradient>
            <clipPath id="clip0_494_266">
              <rect width="65" height="65" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "We Put Your Needs First",
      description:
        "Your success is our success. We take the time to truly understand your business, your challenges, and your goals. Our solutions are tailored specifically to your needs – not a one-size-fits-all approach.",
    },
  ];
  return (
    <section className="mx-auto my-5 grid max-w-[1150px] grid-cols-1 gap-x-28 gap-y-12 px-5 md:grid-cols-2 lg:my-10 lg:px-0">
      {whyUsGridData.map((data) => (
        <WhyUsBlock
          key={data.id}
          icon={data.icon}
          title={data.title}
          description={data.description}
        />
      ))}
    </section>
  );
};

export default WhyUsGrid;
