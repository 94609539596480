import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/autoplay";
import "./custom-swiper.css";  // Make sure to create and include this CSS file
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import CTAButton from 'components/CTAButton';

const VisualLayerSlider = () => {
    return (
        <section className="mt-12 h-auto">
            <div className="flex flex-col gap-5 md:gap-10 mb-3">
                <div className="text-4xl md:text-5xl font-bold text-center">
                    Digital and  Data Transformation
                </div>
                {/* <div className="text-xl text-center">
                    People are your biggest asset, your largest expense, and your <br />most vulnerable security vector.
                </div> */}
                <p className="px-3 text-xl text-brand-darkGrey mt-4 text-pretty text-center w-full">At Cascade Clarity, we help companies navigate the complexities of digital transformation,<br /> focusing on HR, Finance, and Security. Our approach combines precise management consulting<br /> with deep technical expertise to achieve strategic outcomes through technological solutions.</p>

            </div>
            <Swiper
                autoplay={{
                    delay: 1700,
                    disableOnInteraction: false,
                }}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                // slidesPerView={2.5}
                breakpoints={{
                    0: {
                        slidesPerView: 1.6,
                    },
                    768: {
                        slidesPerView: 2.5,
                    },
                }}
                loop
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: 0.9
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={require('assets/Dashboard-1.png')} alt="Direct Manager Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={require('assets/Dashboard-2.png')} alt="CTO Budget Owner Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={require('assets/Dashboard-3.png')} alt="Sales Exec Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={require('assets/Dashboard-1.png')} alt="Direct Manager Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={require('assets/Dashboard-2.png')} alt="CTO Budget Owner Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={require('assets/Dashboard-3.png')} alt="Sales Exec Dashboard" className="swiper-slide-img" loading='lazy' width={"100%"} height={"100%"} />
                </SwiperSlide>

            </Swiper>
            <div className='w-full items-center justify-center flex mt-4 lg:mt-10'>
                <CTAButton btnText='See more' href='/success-stories#Global-HRIS-vs-Payroll' hasArrow opensNewTab={false} />
            </div>
        </section>
    );
}

export default VisualLayerSlider;