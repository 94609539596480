import PrimaryLogo from "components/PrimaryLogo";
import links from "constants/links";
import useScrollWithOffset from "hooks/useScrollWithOffset";
import { HashLink } from "react-router-hash-link";
const Footer = () => {
  const scrollWithOffset = useScrollWithOffset();
  const handleMenuClick = (event, id) => {
    event.stopPropagation();

    // Select all submenus using a common class or attribute
    const submenus = document.querySelectorAll(".mobile-nav-submenu");
    const arrows = document.querySelectorAll(".mobile-nav-menu-arrow");

    // Hide all submenus (except the clicked one) and apply arrow styling
    for (let i = 0; i < submenus.length; i++) {
      const submenu = submenus[i];
      const arrow = arrows[i];

      if (submenu.id !== `mobile-nav-submenu-${id}`) {
        submenu.style.display = "none";
      }
      if (arrow.id !== `mobile-nav-menu-arrow-${id}`) {
        arrow.style.transform = "scaleY(1)";
        arrow.style.fill = "#6163F6";
      }
    }

    // Select the intended submenu
    const submenu = document.getElementById(`mobile-nav-submenu-${id}`);

    // Check if the submenu is already open
    const isOpen = submenu.style.display === "block";

    // Toggle visibility based on current state
    submenu.style.display = isOpen ? "none" : "block";

    // Update the arrow for the intended submenu (optional)
    if (!isOpen) {
      // Update arrow only if submenu is being opened
      const arrow = document.getElementById(`mobile-nav-menu-arrow-${id}`);
      arrow.style.transform = "scaleY(-1)";
      arrow.style.fill = "white";
    } else {
      // Update arrow for closing (change styles if needed)
      const arrow = document.getElementById(`mobile-nav-menu-arrow-${id}`);
      arrow.style.transform = "scaleY(1)";
      arrow.style.fill = "#6163F6";
    }
  };
  const navData = [
    {
      id: 1,
      name: "Services",
      link: "/services",
      submenu: [
        {
          id: 1,
          name: "AI Innovation and Adoption",
          link: "/services/#ai-solutions",
        },
        {
          id: 2,
          name: "Digital Strategy & Alignment",
          link: "/services/#digital-transformation",
        },
        {
          id: 3,
          name: "Strategic Vendor Management",
          link: "/services/#vendor-selection",
        },
        {
          id: 4,
          name: "Enterprise Implementation",
          link: "/services/#integration-services",
        },
      ],
    },
    // {
    //     id: 2,
    //     name: "How We Do It",
    //     link: "/how-we-do-it",
    //     submenu: [
    //         { id: 1, name: "Visual Layer", link: "/how-we-do-it/#visual-layer" },
    //         { id: 2, name: "Integration Strategies", link: "/how-we-do-it/#integration-strategies" },
    //         { id: 3, name: "Process Mining", link: "/how-we-do-it/#process-mining" }
    //     ]
    // },
    {
      id: 3,
      name: "Technology",
      link: "/technology",
      submenu: [
        { id: 1, name: "Generative AI/ML", link: "/technology/#generative-ai" },
        { id: 2, name: "Dashboards", link: "/technology/#dashboards" },
        {
          id: 3,
          name: "Human Capital Management",
          link: "/technology/#human-capital-management",
        },
        {
          id: 4,
          name: "Finance FP&A",
          link: "/technology/#financial-planning",
        },
        { id: 5, name: "Security", link: "/technology/#security" },
        { id: 6, name: "Integration", link: "/technology/#integration" },
      ],
    },
    {
      id: 4,
      name: "Success Stories",
      link: "/success-stories",
      submenu: [
        {
          id: 1,
          name: "AI: HR Automation",
          link: "/success-stories#AI-HR-Automation",
        },
        {
          id: 2,
          name: "Global HRIS vs Payroll",
          link: "/success-stories#Global-HRIS-vs-Payroll",
        },
        {
          id: 3,
          name: "HR: IT Integration",
          link: "/success-stories#HR-IT-Integration",
        },
        {
          id: 4,
          name: "Strategic Dashboard Analytics",
          link: "/success-stories#Strategic-Dashboard-Analytics",
        },
      ],
    },
    {
      id: 5,
      name: "Blog Posts",
      link: "/blog/linkedin",
      submenu: [
        { id: 2, name: "LinkedIn", link: "/blog/linkedin" },
        { id: 1, name: "Articles", link: "/blog/articles" },
        // { id: 3, name: "News (future)", link: "/blog/articles" },
      ],
    },
    {
      id: 6,
      name: "About Us",
      link: "/about-us",
      submenu: [
        { id: 1, name: "Leadership", link: "/leadership" },
        { id: 3, name: "Why Cascade Clarity", link: "/about-us#why-us" },
        { id: 4, name: "Connect with us", link: "/about-us#connect-with-us" },
      ],
    },
  ];
  return (
    <footer className="z-50 mx-auto mb-2 mt-4 flex w-full flex-col items-center justify-center rounded-[13px] bg-brand-darkGrey px-10 py-10 text-white md:mt-20">
      <div className="flex w-full flex-col pb-8 lg:flex-row">
        <div className="mb-10 flex w-full flex-col items-center justify-center gap-10 text-center lg:mb-0 lg:max-w-52 lg:items-start lg:justify-start lg:text-left">
          <a href="/" className="w-32">
            <PrimaryLogo />
          </a>
          <p className="!m-0 text-white">
            Empowering Strategic Decision-Making Through Top-Down Solutions.
          </p>
          <p className="!m-0 text-left text-white">
            We are on{" "}
            <a
              href={links.linkedin}
              target="_blank"
              rel="noreferrer"
              className="text-brand-purple underline hover:text-white"
            >
              Linkedin
            </a>
          </p>
        </div>
        {/* Desktop Footer Content */}
        <div className="mx-auto hidden h-fit w-fit flex-col justify-start gap-10 lg:flex lg:flex-row">
          {navData.map((item) => (
            <div key={item.id} className="flex h-full flex-col gap-4">
              <div className="h-16 text-lg font-bold">
                <a href={item.link} style={{ textDecoration: "none" }}>
                  <span className="hover:text-brand-purple">{item.name}</span>
                </a>
              </div>
              {item.submenu ? (
                item.submenu.map((subItem) => (
                  <HashLink
                    scroll={(el) => scrollWithOffset(el)}
                    smooth
                    style={{ textDecoration: "none" }}
                    key={subItem.id}
                    to={subItem.link}
                    className="flex flex-row gap-5 text-sm tracking-wide hover:text-brand-purple"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="10"
                      viewBox="0 0 8 10"
                      fill="none"
                    >
                      <path
                        d="M8 5L0.5 0.669873L0.5 9.33013L8 5Z"
                        fill="#6163F6"
                      />
                    </svg>
                    {subItem.name}
                  </HashLink>
                ))
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>

        {/* Mobile Footer Content */}
        <nav className="text-md mx-auto flex h-full flex-col items-start gap-5 text-white lg:hidden">
          {navData.map((item, index) =>
            item.submenu ? (
              <div
                key={item.id}
                onClick={(event) => handleMenuClick(event, item.id)}
                // The padding plays a part in detecting the mouse events, take precaution in changing this.
                className="py-2"
              >
                <div className="relative mx-auto flex w-60 cursor-pointer flex-col justify-center gap-3 uppercase tracking-wide">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row items-center justify-between gap-10">
                      <a href={item.link}>
                        <span className="font-bold tracking-wider hover:text-brand-purple">
                          {item.name}
                        </span>
                      </a>
                      <svg
                        onClick={(event) => handleMenuClick(event, item.id)}
                        id={`mobile-nav-menu-arrow-${item.id}`}
                        className="mobile-nav-menu-arrow transform fill-brand-purple transition-transform duration-150 ease-in-out"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                      >
                        <path d="M7 11L13.0622 0.5H0.937822L7 11Z" />
                      </svg>
                    </div>
                    {
                      // Exclude last element from displaying the divider
                      index !== navData.length - 1 && (
                        <div className="h-[1px] w-full bg-gray-100 bg-opacity-20"></div>
                      )
                    }
                  </div>

                  <div
                    id={`mobile-nav-submenu-${item.id}`}
                    className="mobile-nav-submenu hidden w-fit rounded-md bg-brand-darkGrey bg-opacity-20 pl-3 transition-all duration-150 ease-in-out"
                  >
                    {item.submenu.map((subItem) => (
                      <HashLink
                        scroll={(el) => scrollWithOffset(el)}
                        smooth
                        key={subItem.id}
                        to={subItem.link}
                        className="block text-wrap py-2 tracking-wider text-white transition-all duration-150 ease-in-out hover:text-brand-purple"
                      >
                        {subItem.name}
                      </HashLink>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <HashLink
                scroll={(el) => scrollWithOffset(el)}
                smooth
                key={item.id}
                className="py-2"
                to={item.link}
              >
                <div className="relative flex cursor-pointer flex-row items-center justify-center gap-8 uppercase tracking-wide hover:text-brand-purple">
                  <span>{item.name}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                  >
                    <path d="M7 11L13.0622 0.5H0.937822L7 11Z" fill="#6163F6" />
                  </svg>
                </div>
              </HashLink>
            ),
          )}
        </nav>
      </div>

      <div className="flex w-full flex-row justify-between border-t border-gray-50 border-opacity-50 pt-5 font-light tracking-wide">
        <div>
          <a href="/privacy-policy">
            <u>Privacy Policy</u>
          </a>
        </div>
        <div>
          @ {new Date().getFullYear()} powered by&nbsp;
          <a href={links.salesmrkt}>
            <u>Salesmrkt.com</u>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
