const baseUrl = "https://zeyad7eb7ec4890.wpcomstaging.com/wp-json/wp/v2/posts";

export const fetchPosts = async () => {
  try {
    const response = await fetch(baseUrl);
    console.log(response);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    return null;
  }
};
