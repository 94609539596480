import useScrollWithOffset from "hooks/useScrollWithOffset";
import { HashLink } from "react-router-hash-link";

const CTAButton = ({
  hasArrow = false,
  btnText,
  href,
  opensNewTab = true,
  size,
  customBgColorClass,
}) => {
  const scrollWithOffset = useScrollWithOffset();

  let btnSizes = {
    small: "px-7 py-3",
    medium: "px-14 pb-5 pt-4",
  };
  let btnSizeClasses = btnSizes.small;

  switch (size) {
    case 1:
      btnSizeClasses = btnSizes.small;
      break;
    case 2:
      btnSizeClasses = btnSizes.medium;
      break;
    default:
      btnSizeClasses = btnSizes.small;
  }

  let btnBgColor = "bg-primary-gradient";
  if (customBgColorClass) {
    btnBgColor = customBgColorClass;
  }

  return (
    <HashLink
      scroll={(el) => scrollWithOffset(el)}
      smooth
      to={href}
      target={opensNewTab ? "_blank" : ""}
      rel={opensNewTab ? "noreferrer" : ""}
      className={`${btnBgColor} group flex w-fit cursor-pointer flex-row items-center justify-evenly gap-2 rounded-lg text-center text-lg text-white ${btnSizeClasses}`}
    >
      {hasArrow ? (
        <>
          <div
            className="mb-1 mr-4 text-nowrap font-medium group-hover:text-white"
            style={{ textDecoration: "none" }}
          >
            {btnText}
          </div>
          <svg
            class="scale-x-100 transform hover:animate-scale-x group-hover:animate-scale-x"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="16"
            viewBox="0 0 29 16"
            fill="none"
          >
            <path
              d="M28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM0 9H28V7H0V9Z"
              fill="white"
            />
          </svg>
        </>
      ) : (
        <>
          <span>{btnText}</span>
        </>
      )}
    </HashLink>
  );
};

export default CTAButton;
