import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";

const PrivacyPolicy = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <NavBar />
      <SubpageHero
        pageTitle="Privacy Policy"
        testimonial={{
          quote:
            '"Cascade Clarity delivers extraordinary value by bringing executive level acumen and focus through to strategic technical execution"',
          author: "Pam G. CEO LumenaLabs Inc.",
        }}
      />
      <div className="mx-auto max-w-4xl px-6 py-8 text-gray-800">
        <h1 className="mb-6 text-3xl font-bold">Privacy Policy</h1>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            1. Introduction and Scope
          </h2>
          <p className="mb-2">
            Cascade Clarity AI (
            <a
              href="https://cascadeclarity.ai/"
              className="text-blue-600 hover:underline"
            >
              https://cascadeclarity.ai/
            </a>
            ) is a leading artificial intelligence company dedicated to
            developing innovative AI solutions. This Privacy Policy outlines our
            practices regarding the collection, use, and sharing of your
            personal information when you interact with our services, website,
            or products.
          </p>
          <p>
            The purpose of this Privacy Policy is to provide you with a clear
            understanding of how we handle your data, ensuring transparency and
            compliance with applicable data protection laws.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            2. Information Collection
          </h2>
          <h3 className="mb-2 text-xl font-semibold">
            2.1 Types of Personal Data Collected
          </h3>
          <ul className="mb-4 list-inside list-disc">
            <li>
              Basic identification information (e.g., name, email address, phone
              number)
            </li>
            <li>Professional information (e.g., job title, company name)</li>
            <li>Account credentials</li>
            <li>Usage data and interaction with our services</li>
            <li>Device and browser information</li>
            <li>IP address and location data</li>
            <li>Any other information you choose to provide</li>
          </ul>

          <h3 className="mb-2 text-xl font-semibold">
            2.2 Methods of Collection
          </h3>
          <ul className="list-inside list-disc">
            <li>
              Direct input: Information you provide when creating an account,
              using our services, or contacting us
            </li>
            <li>
              Automated technologies: Cookies, web beacons, and similar
              technologies
            </li>
            <li>
              Third-party sources: We may receive information from business
              partners, analytics providers, or advertising networks
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">3. Use of Information</h2>
          <h3 className="mb-2 text-xl font-semibold">3.1 Primary Purposes</h3>
          <ul className="mb-4 list-inside list-disc">
            <li>Providing and improving our AI services</li>
            <li>Personalizing user experience</li>
            <li>
              Communicating with users about our services, updates, and offers
            </li>
            <li>Analyzing usage patterns to enhance our products</li>
            <li>Ensuring the security and integrity of our systems</li>
            <li>Complying with legal obligations</li>
          </ul>

          <h3 className="mb-2 text-xl font-semibold">
            3.2 Legal Bases for Processing
          </h3>
          <ul className="list-inside list-disc">
            <li>Consent: When you have given explicit permission</li>
            <li>
              Contractual necessity: To fulfill our obligations in a contract
              with you
            </li>
            <li>
              Legitimate interests: When processing is necessary for our
              legitimate business interests
            </li>
            <li>
              Legal obligation: To comply with applicable laws and regulations
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            4. Data Sharing and Disclosure
          </h2>
          <h3 className="mb-2 text-xl font-semibold">
            4.1 Circumstances for Sharing
          </h3>
          <ul className="mb-4 list-inside list-disc">
            <li>With your consent</li>
            <li>To comply with legal obligations</li>
            <li>To protect our rights, privacy, safety, or property</li>
            <li>In connection with a business transfer or merger</li>
            <li>With service providers who assist in our operations</li>
          </ul>

          <h3 className="mb-2 text-xl font-semibold">
            4.2 Categories of Third-Party Recipients
          </h3>
          <ul className="list-inside list-disc">
            <li>Cloud service providers</li>
            <li>Analytics and advertising partners</li>
            <li>Payment processors</li>
            <li>Professional advisors (e.g., lawyers, accountants)</li>
            <li>
              Regulatory authorities or government bodies when required by law
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            5. Data Security Measures
          </h2>
          <p className="mb-2">
            We implement robust security measures to protect your personal
            information, including:
          </p>
          <ul className="mb-4 list-inside list-disc">
            <li>Encryption of data in transit and at rest</li>
            <li>Regular security audits and penetration testing</li>
            <li>Access controls and authentication mechanisms</li>
            <li>Employee training on data protection and security practices</li>
            <li>Incident response plans</li>
          </ul>
          <p>
            While we strive to use commercially acceptable means to protect your
            personal data, we cannot guarantee its absolute security.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            6. User Rights and Choices
          </h2>
          <h3 className="mb-2 text-xl font-semibold">
            6.1 GDPR and CCPA Compliance
          </h3>
          <p className="mb-4">
            We comply with the General Data Protection Regulation (GDPR) and the
            California Consumer Privacy Act (CCPA) where applicable. Depending
            on your jurisdiction, you may have the following rights:
          </p>
          <ul className="mb-4 list-inside list-disc">
            <li>Right to access your personal data</li>
            <li>Right to rectification of inaccurate data</li>
            <li>Right to erasure ("right to be forgotten")</li>
            <li>Right to restrict processing</li>
            <li>Right to data portability</li>
            <li>Right to object to processing</li>
            <li>Rights related to automated decision-making and profiling</li>
          </ul>
          <h3 className="mb-2 text-xl font-semibold">
            6.2 Exercising Your Rights
          </h3>
          <p className="mb-4">
            To exercise your rights or make choices about your personal data:
          </p>
          <ul className="list-inside list-disc">
            <li>
              Access your account settings to update or correct your information
            </li>
            <li>Contact us using the information provided in Section 10</li>
            <li>Use the unsubscribe link in our marketing communications</li>
          </ul>
          <p>
            We will respond to your requests within the timeframe specified by
            applicable law.
          </p>
        </section>

        {/* Remaining sections here in a similar format */}

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-semibold">
            10. Contact Information
          </h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our data practices, please contact us at:{" "}
          <a
            href="http://cascadeclarity.ai/about-us#connect-with-us"
            className=" text-brand-purple inline-block"
          >
            http://cascadeclarity.ai/about-us#connect-with-us
          </a>
          </p>
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
