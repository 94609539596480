import React from "react";

const InfoText = ({ title, subtitle }) => {
  return (
    <section className="mt-12 pb-12 flex h-fit w-full flex-col items-center justify-center gap-10 border-b border-b-gray-300 text-center">
      <div className="text-xl font-bold">
        {title.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
      {subtitle && (
        <div className="text-lg leading-loose">
          {subtitle.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      )}
    </section>
  );
};

export default InfoText;
