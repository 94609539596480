import React from "react";
import { useEffect, useState } from "react";
import { fetchPosts } from "./blogService";
import InfoCardVertical from "components/InfoCardVertical";
import parsePostDate from "hooks/useParsePostDate";

const PostList = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetchPosts().then((data) => setPosts(data));
  }, []);

  const minutesToRead = () => {
    return Math.floor(Math.random() * 4) + 1;
  };

  return (
    <div className="my-20">
      {!posts.length ? (
        <div className="col-span-full mx-auto flex h-screen justify-center pt-10">
          <div className="relative">
            <div className="h-[35vw] w-[35vw] rounded-full border-b-8 border-t-8 border-gray-200"></div>
            <div className="border-brand-purple absolute left-0 top-0 h-[35vw] w-[35vw] animate-spin rounded-full border-b-8 border-t-8"></div>
          </div>
        </div>
      ) : (
        <div className="mx-auto mt-10 grid w-full grid-cols-[repeat(auto-fit,minmax(420px,max(420px,100%/4)))] flex-wrap items-start justify-center gap-10">
          {posts.map((post) => (
            <InfoCardVertical
              publishedDate={parsePostDate(post.date_gmt)}
              title={post.title.rendered}
              img={post.jetpack_featured_media_url}
              description={post.excerpt.rendered}
              body={post.excerpt.rendered}
              btnText={"See more"}
              btnLink={`/blog/articles/post/${post.slug}`}
              minutesToRead={minutesToRead()}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PostList;
