// import "./styles.css";
import "./latest-styles.css";
const PostContent = ({ content }) => {
  return (
    <div className="blog-content mx-auto min-h-[700px] w-fit max-w-[1000px] px-5">
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};

export default PostContent;
