const SubpageHero = ({ pageTitle, testimonial }) => {
    return (
        <section className="overflow-hidden rounded-3xl border">
            <div className="relative flex h-full flex-col items-center justify-center lg:justify-between overflow-hidden bg-gradient-to-r from-teal-200 to-purple-200 px-3 py-8 lg:flex-row lg:px-16">
                <div className="z-20 flex text-center lg:text-left lg:w-1/2 flex-col gap-2">
                    <h2 className="text-5xl font-bold text-black">{pageTitle}</h2>
                    <div className="text-base font-medium text-gray-600">
                        <a href="/" className="underline hover:text-gray-800">
                            Home
                        </a>{" "}
                        {">"} {pageTitle}
                    </div>
                </div>
                {testimonial && (
                    <div className="z-20 lg:w-1/2 px-2 lg:pl-8 text-center  mt-7 lg:mt-0 lg:text-right">
                        <p className="testimonial-text text-base md:text-lg italic leading-tight text-justify">
                            {testimonial.quote}
                        </p>
                        <p className="mt-4 lg:mt-2 text-base font-semibold">{testimonial.author}</p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default SubpageHero;
