import ServiceBlock from "components/ServiceBlock";

const ProcessList = () => {
    const serviceBlockData = [
        {
            id: "generative-ai",
            icon: (
                <svg className="mr-auto" xmlns="http://www.w3.org/2000/svg" width="75" height="81" viewBox="0 0 75 81" fill="none">
                    <rect width="75" height="81" rx="9" fill="url(#paint0_linear_21_816)" />
                    <path d="M43.3919 33.4526L41.8245 32.6348C41.2074 32.3111 40.7511 31.7129 40.5372 30.9122C40.3271 30.1436 40.4198 29.392 40.7966 28.7995L41.7469 27.3078C41.9949 26.9178 41.9381 26.4085 41.6125 26.0829L38.097 22.5675C37.7695 22.2419 37.2603 22.1851 36.8722 22.4331L35.3786 23.3834C34.7842 23.7601 34.0364 23.851 33.2319 23.6333C32.4652 23.427 31.8651 22.9726 31.5433 22.3536L30.7255 20.7861C30.5115 20.3772 30.0402 20.1709 29.5972 20.2902L24.7945 21.5755C24.3478 21.6948 24.0449 22.1094 24.0657 22.5694L24.1471 24.4284C24.1774 25.1212 23.9105 25.8008 23.4126 26.2949C23.4126 26.2968 23.2971 26.4104 23.2971 26.4123C22.803 26.9083 22.1215 27.1809 21.4286 27.1468L19.5696 27.0654C19.0793 27.0635 18.695 27.3494 18.5758 27.7943L17.2904 32.5969C17.1711 33.0418 17.3775 33.5113 17.7864 33.7252L19.3538 34.543C19.9709 34.8648 20.4272 35.4649 20.643 36.2657C20.8531 37.0343 20.7604 37.7858 20.3836 38.3783L19.4333 39.8701C19.1853 40.2601 19.2421 40.7693 19.5677 41.0949L23.0832 44.6103C23.4107 44.9378 23.9199 44.9927 24.308 44.7447L25.7997 43.7963C26.3942 43.4196 27.1438 43.325 27.9465 43.5446C28.7132 43.7509 29.3114 44.2052 29.6351 44.8243L30.4529 46.3917C30.6251 46.723 30.9659 46.9199 31.3256 46.9199C31.4108 46.9199 31.4959 46.9085 31.5792 46.8858L36.3819 45.6004C36.8286 45.4812 37.1315 45.0685 37.1107 44.6066L37.0312 42.7457C37.0009 42.0528 37.2678 41.3732 37.7657 40.8791C37.7657 40.8772 37.8812 40.7636 37.8831 40.7618C38.3772 40.2658 39.0625 40.0083 39.7516 40.0272L41.6106 40.1086C42.1141 40.1257 42.4851 39.8247 42.6044 39.3798L43.8917 34.5772C44.011 34.1323 43.8046 33.6628 43.3957 33.4489L43.3919 33.4526ZM30.5873 38.5125C27.8689 38.5125 25.6635 36.309 25.6635 33.5887C25.6635 30.8703 27.867 28.665 30.5873 28.665C33.3057 28.665 35.5111 30.8685 35.5111 33.5887C35.5111 36.3072 33.3076 38.5125 30.5873 38.5125Z" fill="white" />
                    <path d="M56.1105 34.0147C55.7148 33.619 55.0768 33.619 54.6831 34.0147L54.56 34.1377V33.1552C54.56 29.5697 51.6428 26.6523 48.0572 26.6523H46.4821C45.9237 26.6523 45.4731 27.1048 45.4731 27.6613C45.4731 28.2179 45.9237 28.6703 46.4821 28.6703H48.0572C50.5295 28.6703 52.54 30.6808 52.54 33.1531V34.1375L52.4169 34.0145C52.0213 33.6188 51.3833 33.6188 50.9895 34.0145C50.5939 34.4101 50.5939 35.0481 50.9895 35.4418L52.8353 37.2876C53.0246 37.4769 53.282 37.5829 53.5489 37.5829C53.8159 37.5829 54.0733 37.4769 54.2626 37.2876L56.1084 35.4418C56.504 35.0462 56.504 34.4082 56.1084 34.0145L56.1105 34.0147Z" fill="white" />
                    <path d="M34.5265 57.1009H32.9515C30.4791 57.1009 28.4687 55.0905 28.4687 52.6181V51.6337L28.5917 51.7568C28.7886 51.9537 29.048 52.0521 29.3054 52.0521C29.5648 52.0521 29.8222 51.9537 30.0191 51.7568C30.4148 51.3611 30.4148 50.7232 30.0191 50.3294L28.1734 48.4837C27.7929 48.1032 27.1246 48.1069 26.746 48.4837L24.9003 50.3294C24.5046 50.7251 24.5046 51.363 24.9003 51.7568C25.2959 52.1524 25.9339 52.1524 26.3276 51.7568L26.4507 51.6337V52.6162C26.4507 56.2017 29.3679 59.1191 32.9536 59.1191H34.5286C35.087 59.1191 35.5376 58.6667 35.5376 58.1101C35.5376 57.5536 35.087 57.1011 34.5286 57.1011L34.5265 57.1009Z" fill="white" />
                    <path d="M58.3723 50.2308L57.4088 46.6321C57.2933 46.2005 56.9241 45.9184 56.4452 45.9241L55.05 45.9828C54.5919 45.998 54.1413 45.8257 53.8025 45.4849L53.7267 45.4092C53.3973 45.0817 53.2213 44.6331 53.2402 44.175L53.2989 42.7798C53.3178 42.333 53.0244 41.9298 52.5909 41.8162L48.9922 40.8526C48.5605 40.7372 48.1062 40.9359 47.8961 41.3335L47.2827 42.5091C47.0688 42.918 46.6693 43.219 46.1279 43.3647C45.6092 43.5048 45.1113 43.4461 44.7195 43.1963L43.6007 42.4826C43.224 42.2422 42.7299 42.297 42.4118 42.6132L39.7767 45.2483C39.4605 45.5645 39.4056 46.0605 39.646 46.4372L40.3597 47.556C40.6096 47.9479 40.6683 48.4476 40.5206 48.989C40.3806 49.504 40.0796 49.9053 39.6707 50.1192L38.4951 50.7326C38.0975 50.9389 37.8969 51.3951 38.0142 51.8287L38.9778 55.4274C39.0933 55.859 39.4795 56.1562 39.9414 56.1354L41.3366 56.0748C41.789 56.054 42.2452 56.2319 42.569 56.5575L42.6617 56.6503C42.9911 56.9778 43.1672 57.4284 43.1482 57.8864L43.0896 59.2816C43.0706 59.7284 43.3641 60.1316 43.7976 60.2452L47.3963 61.2088C47.4777 61.2315 47.561 61.2409 47.6443 61.2409C47.9945 61.2409 48.3239 61.0497 48.4924 60.7279L49.1057 59.5523C49.3196 59.1434 49.721 58.8424 50.2605 58.6967C50.7773 58.5547 51.279 58.6153 51.6709 58.8651L52.7896 59.5769C53.1664 59.8155 53.6604 59.7643 53.9785 59.4463L56.6137 56.8112C56.9298 56.495 56.9847 55.999 56.7443 55.6223L56.0306 54.5054C55.7807 54.1135 55.722 53.6119 55.8697 53.0705C56.0079 52.5555 56.3108 52.1542 56.7197 51.9422L57.8934 51.3288C58.2909 51.1224 58.4916 50.6662 58.3742 50.2327L58.3723 50.2308ZM48.1932 54.8538C46.0805 54.8538 44.3691 53.1405 44.3691 51.0297C44.3691 48.9171 46.0824 47.2057 48.1932 47.2057C50.3058 47.2057 52.0172 48.919 52.0172 51.0297C52.0172 53.1424 50.3039 54.8538 48.1932 54.8538Z" fill="white" />
                    <defs>
                        <linearGradient id="paint0_linear_21_816" x1="60.3982" y1="-6.35386e-07" x2="37.1349" y2="80.895" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#5EEACB" />
                            <stop offset="1" stop-color="#33BD9E" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
            title: "Purpose-Built Generative AI and Machine Learning",
            description: "Our step-by-step approach ensures smooth integration of business processes. We simplify and streamline operations across your entire enterprise, guiding you from the initial discovery phase to success meetings with your steering committee."
        },
        {
            id: "integration-strategies",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="81" viewBox="0 0 75 81" fill="none">
                    <rect width="75" height="81" rx="9" fill="url(#paint0_linear_21_828)" />
                    <path d="M30.6848 36.8481C31.1295 36.8481 31.5735 36.7897 32.0031 36.6734C32.272 36.6004 32.5345 36.505 32.7875 36.3887C32.7409 36.0732 32.7177 35.7547 32.7177 35.4358C32.7177 33.7179 33.394 32.0855 34.6085 30.8708C34.867 30.6124 35.147 30.3761 35.4452 30.1648C35.125 29.2382 34.5416 28.4268 33.7648 27.8267C32.8814 27.1442 31.8008 26.7771 30.6848 26.7771C29.3445 26.7771 28.0715 27.3044 27.124 28.2518C26.1764 29.1994 25.6492 30.4724 25.6492 31.8125C25.6492 33.1527 26.1768 34.4257 27.124 35.3733C28.0715 36.3209 29.3445 36.8481 30.6848 36.8481Z" fill="white" />
                    <path d="M35.6128 38.9962C36.5603 39.9439 37.8334 40.4711 39.1735 40.4711C40.5139 40.4711 41.7868 39.9438 42.7344 38.9962C43.6821 38.0488 44.2091 36.7757 44.2091 35.4355C44.2091 34.0953 43.6819 32.8224 42.7344 31.8748C41.787 30.9271 40.5139 30.3999 39.1735 30.3999C37.8334 30.3999 36.5604 30.9272 35.6128 31.8748C34.6652 32.8222 34.1379 34.0953 34.1379 35.4355C34.1379 36.7758 34.6652 38.0488 35.6128 38.9962Z" fill="white" />
                    <path d="M45.6293 35.4354C45.6293 35.7543 45.606 36.0728 45.5594 36.3883C45.8124 36.5047 46.075 36.6002 46.3439 36.673C46.7733 36.7893 47.2173 36.8477 47.662 36.8477C49.0023 36.8477 50.2753 36.3205 51.2229 35.373C52.1704 34.4254 52.6978 33.1525 52.6978 31.8123C52.6978 30.472 52.1705 29.1992 51.2229 28.2516C50.2755 27.3039 49.0023 26.7769 47.662 26.7769C46.5458 26.7769 45.4652 27.1439 44.582 27.8265C43.8053 28.4267 43.2218 29.2382 42.9016 30.1647C43.1996 30.376 43.48 30.6123 43.7383 30.8707C44.9529 32.0851 45.6293 33.7173 45.6293 35.4353L45.6293 35.4354Z" fill="white" />
                    <path d="M53.6352 39.7158C51.8884 38.6856 49.6702 38.2681 47.6625 38.2681C47.1258 38.2681 46.5884 38.2968 46.0549 38.3571C45.6435 38.4036 45.2337 38.4686 44.828 38.5531C44.5347 39.0838 44.1675 39.5717 43.7385 40.0003C43.4121 40.3268 43.0514 40.6177 42.6636 40.8675C43.7781 41.1308 44.8835 41.5372 45.8677 42.1178C47.444 43.0474 48.8407 44.5307 48.8407 46.4635V51.6713C49.753 51.6288 50.6675 51.5363 51.5671 51.3794C52.3517 51.2425 53.1448 51.0547 53.8944 50.7835C54.405 50.599 55.9092 50.0005 55.9092 49.3085V42.8401C55.9092 41.4489 54.7464 40.3708 53.6353 39.7157L53.6352 39.7158Z" fill="white" />
                    <path d="M45.1461 43.3386C43.3993 42.3085 41.1812 41.8911 39.1733 41.8911C37.1655 41.8911 34.9472 42.3085 33.2006 43.3386C32.0896 43.9939 30.9268 45.0718 30.9268 46.4632V52.9316C30.9268 53.6556 32.538 54.269 33.0706 54.4532C33.9474 54.7566 34.8821 54.9532 35.7994 55.0865C36.9139 55.2481 38.0476 55.3195 39.1735 55.3195C40.2995 55.3195 41.4332 55.2481 42.5477 55.0865C43.465 54.9532 44.3996 54.7565 45.2765 54.4532C45.809 54.2689 47.4203 53.6556 47.4203 52.9316V46.4632C47.4203 45.072 46.2575 43.9938 45.1465 43.3386H45.1461Z" fill="white" />
                    <path d="M32.4793 42.1178C33.4636 41.5372 34.5689 41.1308 35.6836 40.8675C35.2958 40.6177 34.9349 40.3269 34.6085 40.0003C34.1798 39.5717 33.8125 39.0838 33.5191 38.5531C33.1135 38.4686 32.7037 38.4036 32.2921 38.3571C31.7588 38.2968 31.2215 38.2681 30.6847 38.2681C28.6769 38.2681 26.4586 38.6856 24.712 39.7158C23.601 40.3708 22.438 41.4489 22.438 42.8402V49.3086C22.438 50.0007 23.9422 50.5991 24.4527 50.7836C25.2024 51.0547 25.9954 51.2426 26.7799 51.3795C27.6795 51.5364 28.594 51.6287 29.5065 51.6714V46.4636C29.5066 44.5307 30.9032 43.0474 32.4795 42.1178L32.4793 42.1178Z" fill="white" />
                    <defs>
                        <linearGradient id="paint0_linear_21_828" x1="65.708" y1="-5.54455e-07" x2="37.0673" y2="80.8467" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#67E5FF" />
                            <stop offset="1" stop-color="#3EC4E0" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
            title: "Integration Strategies",
            description: "Our step-by-step approach ensures smooth integration of business processes. We simplify and streamline operations across your entire enterprise, guiding you from the initial discovery phase to success meetings with your steering committee. "
        },
        {
            id: "process-mining",
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="80" viewBox="0 0 75 80" fill="none">
                    <rect width="75" height="80" rx="9" fill="url(#paint0_linear_53_14)" />
                    <path d="M28.1448 36.3931C28.5841 36.3931 29.0226 36.3354 29.4468 36.2205C29.7124 36.1485 29.9717 36.0542 30.2216 35.9393C30.1756 35.6277 30.1526 35.3132 30.1526 34.9982C30.1526 33.3015 30.8206 31.6893 32.0201 30.4896C32.2754 30.2343 32.552 30.001 32.8465 29.7923C32.5302 28.8771 31.9541 28.0758 31.1868 27.4831C30.3144 26.809 29.2471 26.4464 28.1448 26.4464C26.821 26.4464 25.5638 26.9672 24.628 27.9029C23.6921 28.8388 23.1714 30.0961 23.1714 31.4197C23.1714 32.7433 23.6925 34.0005 24.628 34.9365C25.5638 35.8724 26.8211 36.3931 28.1448 36.3931Z" fill="white" />
                    <path d="M33.0123 38.5148C33.9481 39.4507 35.2055 39.9714 36.5291 39.9714C37.8529 39.9714 39.1101 39.4506 40.046 38.5148C40.982 37.579 41.5025 36.3216 41.5025 34.998C41.5025 33.6744 40.9818 32.4171 40.046 31.4812C39.1103 30.5453 37.8529 30.0245 36.5291 30.0245C35.2055 30.0245 33.9482 30.5453 33.0123 31.4812C32.0764 32.417 31.5557 33.6744 31.5557 34.998C31.5557 36.3217 32.0764 37.579 33.0123 38.5148Z" fill="white" />
                    <path d="M42.905 34.9979C42.905 35.3129 42.882 35.6275 42.836 35.9391C43.0858 36.0541 43.3451 36.1484 43.6107 36.2203C44.0348 36.3351 44.4733 36.3928 44.9126 36.3928C46.2363 36.3928 47.4936 35.8721 48.4295 34.9363C49.3653 34.0004 49.8862 32.7432 49.8862 31.4196C49.8862 30.0958 49.3654 28.8387 48.4295 27.9028C47.4937 26.9668 46.2363 26.4463 44.9126 26.4463C43.8101 26.4463 42.7429 26.8088 41.8706 27.483C41.1035 28.0758 40.5272 28.8773 40.2109 29.7923C40.5053 30.001 40.7821 30.2344 41.0373 30.4896C42.2369 31.689 42.9049 33.3011 42.9049 34.9978L42.905 34.9979Z" fill="white" />
                    <path d="M50.8117 39.2256C49.0865 38.2082 46.8957 37.7958 44.9127 37.7958C44.3828 37.7958 43.852 37.8242 43.325 37.8837C42.9187 37.9296 42.514 37.9938 42.1133 38.0773C41.8236 38.6014 41.4609 39.0833 41.0373 39.5066C40.7149 39.8291 40.3586 40.1164 39.9756 40.3631C41.0763 40.6231 42.1681 41.0246 43.1402 41.5979C44.697 42.5161 46.0765 43.9811 46.0765 45.89V51.0335C46.9775 50.9916 47.8807 50.9002 48.7692 50.7452C49.5441 50.61 50.3274 50.4245 51.0678 50.1567C51.572 49.9744 53.0577 49.3833 53.0577 48.6999V42.3114C53.0577 40.9373 51.9093 39.8725 50.8119 39.2255L50.8117 39.2256Z" fill="white" />
                    <path d="M42.4275 42.8037C40.7023 41.7863 38.5117 41.374 36.5286 41.374C34.5455 41.374 32.3546 41.7863 30.6296 42.8037C29.5323 43.4508 28.3838 44.5155 28.3838 45.8897V52.2782C28.3838 52.9933 29.9752 53.5991 30.5012 53.781C31.3672 54.0807 32.2903 54.2749 33.1963 54.4065C34.297 54.5661 35.4167 54.6367 36.5288 54.6367C37.6408 54.6367 38.7605 54.5661 39.8613 54.4065C40.7673 54.2749 41.6903 54.0806 42.5563 53.781C43.0823 53.599 44.6737 52.9932 44.6737 52.2782V45.8897C44.6737 44.5156 43.5253 43.4508 42.4279 42.8037H42.4275Z" fill="white" />
                    <path d="M29.9174 41.5978C30.8895 41.0244 31.9812 40.623 33.0821 40.363C32.699 40.1163 32.3426 39.8291 32.0202 39.5065C31.5969 39.0832 31.2341 38.6013 30.9444 38.0772C30.5437 37.9937 30.139 37.9295 29.7325 37.8836C29.2057 37.8241 28.6751 37.7957 28.1449 37.7957C26.1618 37.7957 23.971 38.2081 22.246 39.2255C21.1486 39.8725 20 40.9372 20 42.3114V48.6999C20 49.3834 21.4857 49.9744 21.9899 50.1567C22.7302 50.4245 23.5135 50.61 24.2883 50.7452C25.1768 50.9001 26.0801 50.9914 26.9812 51.0335V45.89C26.9814 43.981 28.3607 42.5159 29.9175 41.5979L29.9174 41.5978Z" fill="white" />
                    <defs>
                        <linearGradient id="paint0_linear_53_14" x1="66.4195" y1="5.59949e-06" x2="32.3749" y2="34.1443" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A1FDE9" />
                            <stop offset="1" stop-color="#80E0D8" />
                        </linearGradient>
                    </defs>
                </svg>
            ),
            title: "Process Mining and Data Flow Mapping",
            description: "We map out your data flows and analyze your processes to identify inefficiencies and opportunities for improvement. This helps you understand how your processes work and where they can be made better."
        },

    ]
    return (
        <section className="flex flex-col gap-40  max-w-[1150px] mx-auto mt-20">
            {
                serviceBlockData.map((data) => (
                    <ServiceBlock key={data.id} id={data.id} icon={data.icon} title={data.title} description={data.description} />
                ))
            }
        </section>
    );
}

export default ProcessList;