import Footer from "components/Footer";
import InfoText from "components/InfoText";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import CTABanner from "pages/Home/CTABanner";
import ClientList from "pages/Home/ClientList";
import Connect from "pages/Home/Connect";
import WhyUsGrid from "./WhyUsGrid";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";

const AboutUs = () => {
  return (
    <main className="w-full px-2 md:px-10">
      <Helmet>
        <title>
          About Us | Cascade Clarity - Executive-Level Business Solutions
        </title>
        <meta
          name="description"
          content='"Cascade Clarity delivers extraordinary value by bringing executive level business problem understanding and focus and tying that to strategic technical execution" - Pam G. CEO LumenaLabs Inc.'
        />
      </Helmet>
      <NavBar />
      <SubpageHero
        pageTitle="About Us"
        testimonial={{
          quote:
            "\"Cascade Clarity delivers extraordinary value by bringing executive level acumen and focus through to strategic technical execution\"",
          author: "Pam G. CEO LumenaLabs Inc.",
        }}
      />

      <section
        id="why-us"
        className="my-0 flex w-full flex-col items-center justify-center gap-6 rounded-lg bg-brand-lightBlue bg-opacity-10 px-5 py-10 text-center"
      >
        <h2 className="text-4xl font-bold text-brand-purple">
          Why Choose Cascade Clarity?
        </h2>
        <div className="max-w-3xl text-xl text-justify leading-relaxed mx-auto">
        Our mission is to bridge the gap between executive vision and operational reality in large enterprises. 
        As seasoned business operators with deep technical expertise, we uniquely combine C-suite strategic guidance with hands-on implementation.
        </div>
        <div className="mt-4 max-w-3xl text-xl text-justify">
        <span className="font-bold">What sets us apart?</span> Our comprehensive understanding of both business operations and technical solutions, 
          particularly in HR, Finance, and IT intersections. We excel at both strategic planning and tactical execution.
        </div>
      </section>

      <WhyUsGrid />

      <ContactForm />
      {/* <CTABanner /> */}
      {/* <Connect />
            <ClientList /> */}
      <Footer />
    </main>
  );
};

export default AboutUs;
