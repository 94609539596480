import Footer from "components/Footer";
import NavBar from "components/Navbar";
import SubpageHero from "components/SubpageHero";
import { Helmet } from "react-helmet";

const Leadership = () => {

    const leadershipData = [
        {
            name: "John Cuellar",
            role: "Founder & CEO",
            description: (
                <span>
                    John co-founded SyncHR and lead the company through its early growth stage and then served as CTO for 10+ years. Prior to co-founding SyncHR, John was the CEO of Harbor Technology Group (HTG), a systems integration company focused on web-based solutions automating HR processes for fortune 500 companies. HTG was acquired by Mellon Financial Corporation. Before HTG, John worked in Eastern Europe for the MBA Enterprise Corps performing privatization and M&A consulting. Other prior work endeavors include working with the Swiss Bank Corporation in their derivatives pricing group and as a senior manager for the US Navy. John holds a BS in Electrical and Computer Engineering from the University of California at Santa Barbara and an MBA from the Haas School of Business at the University of California at Berkeley.
                    <br />
                    <br />
                    John lives in San Francisco and enjoys outdoor sports such as snowboarding, surfing, golf, cycling, and running. He likes to exercise the "other" side of his brain by playing the guitar and piano. John also has two sons that are both currently working in the Tech Industry.
                </span>
            ),
            img: require("assets/images/leadership/john.png"),
        },
        {
            name: "Margaret-Ann Cole",
            role: "Founding Member",
            description: (
                <span>
                    Margaret-Ann is a global business leader with expertise in developing more effective and efficient ways of leveraging individual potential within organizations to achieve business success. With 25+ years’ experience developing leaders on all aspects of strategic planning and workforce transformation, she is a recognized thought leader and spokesperson in areas of human resources, talent, leadership, and culture. Working with organizations of all sizes and industries, she works to unleash the potential of talent and up-level core business operations. Her experience includes:<br />
                    <br />
                    <ul className="list-inside list-disc">
                        <li>Serving as Chief People and Transformation Officer for a global purpose driven PR communications company, Porter Novelli.</li>
                        <li>MD and NA leader for Workforce Transformation practice at Accenture.</li>
                        <li>SVP and Leader of the NA Career and Talent practice for Right Management.</li>
                        <li>Founder of Margaret-Ann Cole Associates, a global People and Workforce Strategy consulting firm.</li>
                        <li>Leader of hundreds of strategy and transformation projects over the last 25+ years.</li>
                    </ul>
                    <br />
                    Margaret-Ann received her Bachelor of Science degree in Psychology and Economics from Fordham University and her Master of Business Administration from TRIUM, an alliance of the New York University Stern School, the London School of Economics and Politics, and the HEC School of Management in Paris.
                </span>
            ),
            img: require("assets/images/leadership/margaret.png"),
        },
        {
            name: "Steve Moritz",
            role: "Advisor",
            description: (
                <span>
                    Steve is a senior HR process and technology executive and thought leader. He has broad experience in global HRIS leadership, product design, and HR technology consulting.  He has a passion for process design and streamlining, and his expertise spans the full project lifecycle from product design, vision, and strategy through implementation and post go-live maintenance and improvement.
                    Steve has successfully led forward-thinking HRIS solutions, both custom-built and major ERP offerings. He has also led point solutions for Compensation, Benefits, Performance Management, Talent Acquisition, and all other functional areas of HR. He has worked across multiple industries and companies including Dell, General Motors, American Express, and Marsh & Mclennan.
                    Steve is a strong team-player and respected leader whose clear communication style ensures successful collaboration with everyone from senior executives to front-line staff, as well as internal and external partners and vendors.                </span>
            ),
            img: require("assets/images/leadership/steve.jpg"),
        },
        {
            name: "Don Rudolph",
            role: "Advisor",
            description: (
                <span>
                    Don is a senior information technology executive and strategic business partner with extensive experience in a wide range of companies and industries. Don specializes in Information Security strategy, management, and governance. A background in FFIEC, PCI, HIPAA, SOC 1&2, and ISO 27001 regulatory regimes in a variety of industries and organizations gives him a real world perspective on information security compliance. He has a demonstrated history of successfully implementing innovative technical solutions that meet strategic and tactical business objectives. Recognized as an effective collaborator, mentor, and servant leader, he helps individuals and organizations reach their highest potential. His strong service ethic produces a successful customer service culture on teams he leads. Don has the ability to communicate technical information to business partners with clarity. An effective change agent, he is ready to engage with others in the creative change process.
                </span>
            ),
            img: require("assets/images/leadership/don.png"),
        },
        {
            name: "Jonathan Goldin",
            role: "Advisor",
            description: (
                <span>
                    Jonathan is a growth focused global human capital, transformation, and business executive who works with leaders and stakeholders to develop and scale more aligned, productive, engaged, and agile workplaces that drive business results. Jonathan brings a breadth and depth of experience, working with businesses of all sizes, complexity and industry, to ensure an organization’s talent, culture, process and technology practices enable business strategy, drive growth, and deliver value. Working with an effective balance of strategic vision and tactical execution, Jonathan helps identify critical value drivers, assess needs and gaps in capabilities, translate opportunities into action plans, and mobilize stakeholders to drive execution. Jonathan’s experience includes leadership roles as a Chief People Officer, private equity operating executive, VP of HR Strategy & Transformation at a Global Fortune 500, human capital & business transformation consultant, and General Counsel and Head of Business Development at a successful start-up.
                    <br />
                    <br />
                    Jonathan is a graduate of Hobart & William Smith Colleges, and earned his law degree, cum laude, from the University of Maine School of Law. He lives near the beach in New Jersey with his wife, Cathy, and 3 children, where he enjoys running, biking, SUP surfing, skiing, ice hockey, and struggling on the banjo, all in an attempt to stay young at heart.
                </span>
            ),
            img: require("assets/images/leadership/jonathan.png"),
        },
        {
            name: "Usman Raza",
            role: "Advisor",
            description: (
                <span>
                    Usman is an accomplished physician-turned-product leader, known for his impressive track record in building scalable digital health and virtual care products. Over the last seven years in the tech industry, Usman has spearheaded the development of patient-facing chatbots, digital therapeutics for mental disorders and lifestyle interventions, data platforms for generating business insights and real-world evidence, patient services platforms and more. Prior to transitioning to the tech industry, Usman spent almost a decade as a physician and public health professional. He has led large-scale public health interventions for HIV, polio vaccination, maternal child health services, nutrition programs, and digital transformation initiatives at public and private organizations. Usman has also consulted for UN agencies, federal and state health departments on epidemic modeling, monitoring and evaluation, and capacity building for health professionals. Usman is driven by his passion for utilizing game-changing technologies to revolutionize access to care, improve patient outcomes, and transform the way providers deliver care. Born and raised in Pakistan, Usman earned his medical degree and residency from Khyber Medical College before receiving his MS degree from Harvard School of Public Health and MIMS from UC Berkeley School of Information.
                </span>
            ),
            img: require("assets/images/leadership/usman.png"),
        },

    ]
    return (
        <main className="w-full px-2 md:px-10">
            <Helmet>
                <title>Leadership | Cascade Clarity</title>
            </Helmet>
            <NavBar />
            <SubpageHero
                pageTitle="Leadership"
                testimonial={{
                quote: "\"Cascade Clarity delivers extraordinary value by bringing executive level acumen and focus through to strategic technical execution\"",
                author: "Pam G. CEO LumenaLabs Inc.", }}
            />
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-16 my-10 ">
                {leadershipData.map((leader, index) => (
                    <div key={index} className="flex flex-col gap-8 bg-brand-darkGrey text-white  border border-gray-300 rounded-[13px] py-10 px-5 lg:p-10 lg:pb-20  w-fit">
                        <div className="flex flex-row gap-3 lg:gap-6 items-center justify-center w-fit">
                            <div className=" lg:mb-auto w-20 h-20 lg:w-40 lg:h-40 rounded-full overflow-hidden">
                                <img src={leader.img} alt={leader.name} className="object-cover w-full h-full" />
                            </div>
                            <div className="flex flex-col gap-1.5 lg:gap-3">
                                <h2 className="text-4xl lg:text-5xl font-bold">{leader.name}</h2>
                                <h3 className="text-2xl font-semibold">{leader.role}</h3>
                            </div>
                        </div>
                        <div className="w-full max-w-[1000px]">
                            <p className="text-lg">{leader.description}</p>
                        </div>
                    </div>
                ))}
            </section>
            <Footer />
        </main>
    );
}

export default Leadership;