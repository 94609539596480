import CTAButton from "components/CTAButton";

const HowWeDoitSection = () => {
    return (
        <section className="grid grid-cols-2 gap-16 justify-between items-center mx-auto max-w-[1400px] my-20 px-10 ">
            <div className="w-full">
                <img src={require('assets/images/home/how-we-do-it.png')} alt="Employee Life Cycle" width="100%" height="100%" loading="lazy" />
            </div>
            <div className="gap-10 flex flex-col max-w-[500px] ml-auto ">
                <h2 className="text-5xl font-semibold text-brand-darkGrey">How We Do It</h2>
                <p className="text-lg text-brand-darkGrey mt-4 text-pretty">At Cascade Clarity, we help companies navigate the complexities of digital transformation, focusing on HR, Finance, and Security. Our approach combines precise management consulting with deep technical expertise to achieve strategic outcomes through technological solutions.</p>
                <CTAButton btnText='Book a Call' href='#' hasArrow opensNewTab={false} />
                <a href="/" className="hover:text-brand-purple text-black">Learn more</a>
            </div>
        </section>
    );
}

export default HowWeDoitSection;