import CTAButton from "components/CTAButton";
import links from "constants/links";
import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import AnimatedCheckmark from "components/AnimatedCheckmark";

const ContactForm = () => {
  const [showFormSpinner, setShowFormSpinner] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm();

  useEffect(() => {
    reset();
  }, [submissionSuccess]);

  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
    });
  }, []);

  const onSubmit = (data) => {
    setShowFormSpinner(true);
    clearErrors();

    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        {
          from_name: data.user_name,
          reply_to: data.user_email,
          message: data.message,
        },
      )
      .then(
        () => {
          setSubmissionSuccess(true);
          setTimeout(() => {
            setSubmissionSuccess(false);
          }, 1200);
        },
        (error) => {
          console.log(error);
          setError("api", {
            type: "manual",
            message:
              "An error occurred while sending the message. Please try again.",
          });
        },
      )
      .finally(() => setShowFormSpinner(false));
  };

  return (
    <section
      className="mx-auto w-full max-w-[1500px] px-5 py-5 lg:px-[10%] lg:py-10"
      id="connect-with-us"
    >
      <div className="grid w-fit grid-cols-1 gap-10 pb-10 lg:grid-cols-2">
        <div className="lg:mt-[60px]">
          <h2 className="text-center text-3xl font-bold text-[#06122C] lg:text-left lg:text-4xl lg:leading-[52.5px]">
            Do you have a support issue or have any questions?
          </h2>
          <p className="mt-10 text-wrap text-xl text-[#06122C] lg:leading-[44.54px]">
            Send us a message by filling in the information or by sending us an
            email to{" "}
            <strong className="text-primary">
              <a
                href="mailto:Client.Support@EZSurveyPro.com"
                target="_blank"
                rel="noreferrer"
              >
                gainclarity@cascadeclarity.ai
              </a>
            </strong>
            , and we will get back to you within 24 hours.
          </p>
          <p className="mt-10 text-xl text-[#06122C] lg:leading-[44.54px]">
            Please provide the information requested below to schedule one or
            for more information.
          </p>
        </div>
        <div className="h-fit">
          <InlineWidget url={links.calendly} />
        </div>
      </div>
      <div className="relative">
        {showFormSpinner && (
          <div class="absolute flex h-full w-full items-center justify-center rounded-2xl bg-white bg-opacity-50">
            <div class="relative">
              <div class="h-24 w-24 rounded-full border-b-8 border-t-8 border-gray-200"></div>
              <div class="absolute left-0 top-0 h-24 w-24 animate-spin rounded-full border-b-8 border-t-8 border-brand-purple"></div>
            </div>
          </div>
        )}
        {submissionSuccess && (
          <div class="absolute flex h-full w-full items-center justify-center rounded-2xl bg-white bg-opacity-50">
            <div class="relative">
              <AnimatedCheckmark />
            </div>
          </div>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="h-auto w-full space-y-5 rounded-2xl bg-[#FBFBFB] p-3 lg:space-y-10 lg:p-10"
        >
          <div className="text-center text-lg font-bold sm:text-left sm:text-[44px]">
            What's on your mind? Ask us anything
          </div>
          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="grid w-full grid-cols-1 gap-5 sm:w-2/3">
              <input
                {...register("user_name", {
                  required: "Full name is required",
                })}
                placeholder="Full name"
                className="w-full rounded-lg px-8 py-4 text-sm placeholder:text-[#06122C]"
              />
              {errors.user_name && (
                <span className="text-red-500">{errors.user_name.message}</span>
              )}

              <input
                placeholder="Email Address"
                className="w-full rounded-lg px-8 py-4 text-sm placeholder:text-[#06122C]"
                {...register("user_email", {
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.user_email && (
                <span className="text-red-500">
                  {errors.user_email.message}
                </span>
              )}
            </div>
            <div>
              <textarea
                rows={8}
                {...register("message", { required: "Message is required" })}
                placeholder="Message"
                className="w-full resize-none rounded-lg px-8 py-4 text-sm placeholder:text-[#06122C]"
              />
              {errors.message && (
                <span className="text-red-500">{errors.message.message}</span>
              )}
            </div>
          </div>
          {errors.api && (
            <span className="text-red-500">{errors.api.message}</span>
          )}
          <button
            type="submit"
            className={`bg-primary-gradient group mx-auto flex w-fit cursor-pointer flex-row items-center justify-evenly gap-2 rounded-lg px-7 py-3 text-center text-lg text-white lg:mx-0`}
          >
            <span className="mb-1 mr-4 text-nowrap font-medium">Submit</span>
            <svg
              class="scale-x-100 transform hover:animate-scale-x group-hover:animate-scale-x"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="16"
              viewBox="0 0 29 16"
              fill="none"
            >
              <path
                d="M28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM0 9H28V7H0V9Z"
                fill="white"
              />
            </svg>
          </button>
        </form>
      </div>
    </section>
  );
};
export default ContactForm;
