import CTAButton from "components/CTAButton";

const ServiceCard = ({ service }) => {
    return (
        <div className={`${service.bgColorClass} text-white xl:min-w-[300px] max-w-[650] w-full rounded-xl flex flex-col pl-4 xl:pl-10 pt-5 xl:pt-10`}>
            <div className="flex flex-row gap-5 items-center">
                <div className="">
                    {service.logo}
                </div>
                <div className="text-xl lg:text-4xl font-bold my-10 xl:mr-10 mr-2 w-fit">
                    {service.title}
                </div>
            </div>
            <div className="mt-auto ml-auto">
                <CTAButton btnText='See more' href={service.btnLink} hasArrow opensNewTab={false} customBgColorClass={"bg-dark-gradient"} />
            </div>
        </div>
    );
}

export default ServiceCard;